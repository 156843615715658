body.compensate-for-scrollbar {
    margin: 0 !important;
}

.fancybox-active {
    height: auto;
}

.fancybox-is-hidden {
    left: -9999px;
    margin: 0;
    position: absolute !important;
    top: -9999px;
    visibility: hidden;
}

.fancybox-container {
    -webkit-backface-visibility: hidden;
    height: 100%;
    left: 0;
    outline: none;
    position: fixed;
    -webkit-tap-highlight-color: transparent;
    top: 0;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    width: 100%;
    z-index: 99992;
}

.fancybox-container * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.fancybox-outer {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
}

.fancybox-bg {
    background: rgb(30, 30, 30);
    opacity: 0;
    -webkit-transition-duration: inherit;
    -o-transition-duration: inherit;
    transition-duration: inherit;
    -webkit-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
    -o-transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
    transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
}

.fancybox-is-open .fancybox-bg {
    opacity: 0.9;
    -webkit-transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
    -o-transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
    transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
    direction: ltr;
    opacity: 0;
    position: absolute;
    -webkit-transition: opacity 0.25s ease, visibility 0s ease 0.25s;
    -o-transition: opacity 0.25s ease, visibility 0s ease 0.25s;
    transition: opacity 0.25s ease, visibility 0s ease 0.25s;
    visibility: hidden;
    z-index: 99997;
}

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease 0s, visibility 0s ease 0s;
    -o-transition: opacity 0.25s ease 0s, visibility 0s ease 0s;
    transition: opacity 0.25s ease 0s, visibility 0s ease 0s;
    visibility: visible;
}

.fancybox-infobar {
    color: #ccc;
    font-size: 13px;
    -webkit-font-smoothing: subpixel-antialiased;
    height: 44px;
    left: 0;
    line-height: 44px;
    min-width: 44px;
    mix-blend-mode: difference;
    padding: 0 10px;
    pointer-events: none;
    top: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.fancybox-toolbar {
    right: 0;
    top: 0;
}

.fancybox-stage {
    direction: ltr;
    overflow: visible;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    z-index: 99994;
}

.fancybox-is-open .fancybox-stage {
    overflow: hidden;
}

.fancybox-slide {
    -webkit-backface-visibility: hidden;
    /* Using without prefix would break IE11 */
    display: none;
    height: 100%;
    left: 0;
    outline: none;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 44px;
    position: absolute;
    text-align: center;
    top: 0;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    -o-transition-property: transform, opacity;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    white-space: normal;
    width: 100%;
    z-index: 99994;
}

.fancybox-slide::before {
    content: "";
    display: inline-block;
    font-size: 0;
    height: 100%;
    vertical-align: middle;
    width: 0;
}

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
    display: block;
}

.fancybox-slide--image {
    overflow: hidden;
    padding: 44px 0;
}

.fancybox-slide--image::before {
    display: none;
}

.fancybox-slide--html {
    padding: 6px;
}

.fancybox-content {
    background: #fff;
    display: inline-block;
    margin: 0;
    max-width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 44px;
    position: relative;
    text-align: left;
    vertical-align: middle;
}

.fancybox-slide--image .fancybox-content {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
    animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
    -webkit-backface-visibility: hidden;
    background: transparent;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    left: 0;
    max-width: none;
    overflow: visible;
    padding: 0;
    position: absolute;
    top: 0;
    -ms-transform-origin: top left;
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    -o-transition-property: transform, opacity;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 99995;
}

.fancybox-can-zoomOut .fancybox-content {
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
}

.fancybox-can-zoomIn .fancybox-content {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
}

.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
    cursor: -webkit-grab;
    cursor: grab;
}

.fancybox-is-grabbing .fancybox-content {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.fancybox-container [data-selectable=true] {
    cursor: text;
}

.fancybox-image,
.fancybox-spaceball {
    background: transparent;
    border: 0;
    height: 100%;
    left: 0;
    margin: 0;
    max-height: none;
    max-width: none;
    padding: 0;
    position: absolute;
    top: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
}

.fancybox-spaceball {
    z-index: 1;
}

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
    height: 100%;
    overflow: visible;
    padding: 0;
    width: 100%;
}

.fancybox-slide--video .fancybox-content {
    background: #000;
}

.fancybox-slide--map .fancybox-content {
    background: #e5e3df;
}

.fancybox-slide--iframe .fancybox-content {
    background: #fff;
}

.fancybox-video,
.fancybox-iframe {
    background: transparent;
    border: 0;
    display: block;
    height: 100%;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;
}

/* Fix iOS */
.fancybox-iframe {
    left: 0;
    position: absolute;
    top: 0;
}

.fancybox-error {
    background: #fff;
    cursor: default;
    max-width: 400px;
    padding: 40px;
    width: 100%;
}

.fancybox-error p {
    color: #444;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    padding: 0;
}

/* Buttons */
.fancybox-button {
    background: rgba(30, 30, 30, 0.6);
    border: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: pointer;
    display: inline-block;
    height: 44px;
    margin: 0;
    padding: 10px;
    position: relative;
    -webkit-transition: color 0.2s;
    -o-transition: color 0.2s;
    transition: color 0.2s;
    vertical-align: top;
    visibility: inherit;
    width: 44px;
}

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
    color: #ccc;
}

.fancybox-button:hover {
    color: #fff;
}

.fancybox-button:focus {
    outline: none;
}

.fancybox-button.fancybox-focus {
    outline: 1px dotted;
}

.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
    color: #888;
    cursor: default;
    outline: none;
}

/* Fix IE11 */
.fancybox-button div {
    height: 100%;
}

.fancybox-button svg {
    display: block;
    height: 100%;
    overflow: visible;
    position: relative;
    width: 100%;
}

.fancybox-button svg path {
    fill: currentColor;
    stroke-width: 0;
}

.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
    display: none;
}

.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
    display: none;
}

.fancybox-progress {
    background: #ff5268;
    height: 2px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -ms-transform: scaleX(0);
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -ms-transform-origin: 0;
    -webkit-transform-origin: 0;
    transform-origin: 0;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
    z-index: 99998;
}

/* Close button on the top right corner of html content */
.fancybox-close-small {
    background: transparent;
    border: 0;
    border-radius: 0;
    color: #ccc;
    cursor: pointer;
    opacity: 0.8;
    padding: 8px;
    position: absolute;
    right: -12px;
    top: -44px;
    z-index: 401;
}

.fancybox-close-small:hover {
    color: #fff;
    opacity: 1;
}

.fancybox-slide--html .fancybox-close-small {
    color: currentColor;
    padding: 10px;
    right: 0;
    top: 0;
}

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
    overflow: hidden;
}

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
    display: none;
}

/* Navigation arrows */
.fancybox-navigation .fancybox-button {
    background-clip: content-box;
    height: 100px;
    opacity: 0;
    position: absolute;
    top: calc(50% - 50px);
    width: 70px;
}

.fancybox-navigation .fancybox-button div {
    padding: 7px;
}

.fancybox-navigation .fancybox-button--arrow_left {
    left: 0;
    left: env(safe-area-inset-left);
    padding: 31px 26px 31px 6px;
}

.fancybox-navigation .fancybox-button--arrow_right {
    padding: 31px 6px 31px 26px;
    right: 0;
    right: env(safe-area-inset-right);
}

/* Caption */
.fancybox-caption {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.85)), color-stop(50%, rgba(0, 0, 0, 0.3)), color-stop(65%, rgba(0, 0, 0, 0.15)), color-stop(75.5%, rgba(0, 0, 0, 0.075)), color-stop(82.85%, rgba(0, 0, 0, 0.037)), color-stop(88%, rgba(0, 0, 0, 0.019)), to(rgba(0, 0, 0, 0)));
    background: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.15) 65%, rgba(0, 0, 0, 0.075) 75.5%, rgba(0, 0, 0, 0.037) 82.85%, rgba(0, 0, 0, 0.019) 88%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(to top, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.15) 65%, rgba(0, 0, 0, 0.075) 75.5%, rgba(0, 0, 0, 0.037) 82.85%, rgba(0, 0, 0, 0.019) 88%, rgba(0, 0, 0, 0) 100%);
    bottom: 0;
    color: #eee;
    font-size: 14px;
    font-weight: 400;
    left: 0;
    line-height: 1.5;
    padding: 75px 44px 25px 44px;
    pointer-events: none;
    right: 0;
    text-align: center;
    z-index: 99996;
}

@supports (padding: max(0px)) {
    .fancybox-caption {
        padding: 75px max(44px, env(safe-area-inset-right)) max(25px, env(safe-area-inset-bottom)) max(44px, env(safe-area-inset-left));
    }
}
.fancybox-caption--separate {
    margin-top: -50px;
}

.fancybox-caption__body {
    max-height: 50vh;
    overflow: auto;
    pointer-events: all;
}

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
    color: #ccc;
    text-decoration: none;
}

.fancybox-caption a:hover {
    color: #fff;
    text-decoration: underline;
}

/* Loading indicator */
.fancybox-loading {
    -webkit-animation: fancybox-rotate 1s linear infinite;
    animation: fancybox-rotate 1s linear infinite;
    background: transparent;
    border: 4px solid #888;
    border-bottom-color: #fff;
    border-radius: 50%;
    height: 50px;
    left: 50%;
    margin: -25px 0 0 -25px;
    opacity: 0.7;
    padding: 0;
    position: absolute;
    top: 50%;
    width: 50px;
    z-index: 99999;
}

@-webkit-keyframes fancybox-rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes fancybox-rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
/* Transition effects */
.fancybox-animated {
    -webkit-transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
    -o-transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
    transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
}

/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--next {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--current {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
    opacity: 0;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.fancybox-fx-fade.fancybox-slide--current {
    opacity: 1;
}

/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
    opacity: 0;
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--next {
    opacity: 0;
    -webkit-transform: scale3d(0.5, 0.5, 0.5);
    transform: scale3d(0.5, 0.5, 0.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--current {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
}

/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
    opacity: 0;
    -ms-transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
}

.fancybox-fx-rotate.fancybox-slide--next {
    opacity: 0;
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}

.fancybox-fx-rotate.fancybox-slide--current {
    opacity: 1;
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous {
    opacity: 0;
    -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
    transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--next {
    opacity: 0;
    -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
    transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--current {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
}

/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous {
    -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
    transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
}

.fancybox-fx-tube.fancybox-slide--next {
    -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
    transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
}

.fancybox-fx-tube.fancybox-slide--current {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
}

/* Styling for Small-Screen Devices */
/* Share */
.fancybox-share {
    background: #f4f4f4;
    border-radius: 3px;
    max-width: 90%;
    padding: 30px;
    text-align: center;
}

.fancybox-share h1 {
    color: #222;
    font-size: 35px;
    font-weight: 700;
    margin: 0 0 20px 0;
}

.fancybox-share p {
    margin: 0;
    padding: 0;
}

.fancybox-share__button {
    border: 0;
    border-radius: 3px;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    margin: 0 5px 10px 5px;
    min-width: 130px;
    padding: 0 15px;
    text-decoration: none;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
}

.fancybox-share__button:visited,
.fancybox-share__button:link {
    color: #fff;
}

.fancybox-share__button:hover {
    text-decoration: none;
}

.fancybox-share__button--fb {
    background: #3b5998;
}

.fancybox-share__button--fb:hover {
    background: #344e86;
}

.fancybox-share__button--pt {
    background: #bd081d;
}

.fancybox-share__button--pt:hover {
    background: #aa0719;
}

.fancybox-share__button--tw {
    background: #1da1f2;
}

.fancybox-share__button--tw:hover {
    background: #0d95e8;
}

.fancybox-share__button svg {
    height: 25px;
    margin-right: 7px;
    position: relative;
    top: -1px;
    vertical-align: middle;
    width: 25px;
}

.fancybox-share__button svg path {
    fill: #fff;
}

.fancybox-share__input {
    background: transparent;
    border: 0;
    border-bottom: 1px solid #d7d7d7;
    border-radius: 0;
    color: #5d5b5b;
    font-size: 14px;
    margin: 10px 0 0 0;
    outline: none;
    padding: 10px 15px;
    width: 100%;
}

/* Thumbs */
.fancybox-thumbs {
    background: #ddd;
    bottom: 0;
    display: none;
    margin: 0;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    padding: 2px 2px 4px 2px;
    position: absolute;
    right: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    top: 0;
    width: 212px;
    z-index: 99995;
}

.fancybox-thumbs-x {
    overflow-x: auto;
    overflow-y: hidden;
}

.fancybox-show-thumbs .fancybox-thumbs {
    display: block;
}

.fancybox-show-thumbs .fancybox-inner {
    right: 212px;
}

.fancybox-thumbs__list {
    font-size: 0;
    height: 100%;
    list-style: none;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    position: relative;
    white-space: nowrap;
    width: 100%;
}

.fancybox-thumbs-x .fancybox-thumbs__list {
    overflow: hidden;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
    width: 7px;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
    background: #2a2a2a;
    border-radius: 10px;
}

.fancybox-thumbs__list a {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: rgba(0, 0, 0, 0.1);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    float: left;
    height: 75px;
    margin: 2px;
    max-height: calc(100% - 8px);
    max-width: calc(50% - 4px);
    outline: none;
    overflow: hidden;
    padding: 0;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    width: 100px;
}

.fancybox-thumbs__list a::before {
    border: 6px solid #ff5268;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    z-index: 99991;
}

.fancybox-thumbs__list a:focus::before {
    opacity: 0.5;
}

.fancybox-thumbs__list a.fancybox-thumbs-active::before {
    opacity: 1;
}

/* Styling for Small-Screen Devices */
/* Slider */
.slick-slider {
    position: relative;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.slick-track:before,
.slick-track:after {
    display: table;
    content: "";
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

[dir=rtl] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

.slick-slide {
    outline: none;
}

.slick-arrow {
    background: #F5F5F5 url(../../../images/arrow-left-gray.svg) center/contain no-repeat;
    background-size: 25% !important;
    background-position-x: 46%;
    z-index: 2;
    position: absolute;
    top: 45%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    border: none;
    font-size: 0;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    cursor: pointer;
    outline: none;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.slick-arrow.slick-disabled {
    opacity: 0;
    visibility: hidden;
}

.slick-prev {
    left: 30px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    background-size: 4px;
}

.slick-next {
    -webkit-transform: rotate(180deg) translateY(50%);
    -ms-transform: rotate(180deg) translateY(50%);
    transform: rotate(180deg) translateY(50%);
    right: 30px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    background-size: 4px;
}

.slick-next:before, .slick-prev:before {
    display: none;
}

.slick-next:hover, .slick-prev:hover {
    background: #F2F3F7 url(../../../images/arrow-left-gray.svg) center no-repeat;
    background-position-x: 46%;
}

.slick-dots {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 30px;
}
.slick-dots li {
    margin: 0 10px;
    width: 78px;
    padding: 10px 0;
    cursor: pointer;
    position: relative;
    background: 0;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.slick-dots li:after {
    content: "";
    background: #B7B7B7;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.slick-dots li:only-child {
    display: none;
}
.slick-dots li.slick-active:after {
    background: #707070;
}
.slick-dots li button {
    display: none;
}
.nice-select {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color: #fff;
    border-radius: 0;
    border: solid 1px #464646;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    clear: both;
    cursor: pointer;
    display: block;
    float: left;
    font-family: inherit;
    font-size: 14px;
    font-weight: normal;
    height: 42px;
    line-height: 40px;
    outline: none;
    padding-left: 20px;
    padding-right: 32px;
    position: relative;
    text-align: left !important;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    width: auto;
    margin-bottom: 20px;
}
.nice-select:hover {
    border-color: #dbdbdb;
}
.nice-select:active, .nice-select.open, .nice-select:focus {
    border-color: #868686;
}
.nice-select:after {
    border-bottom: 2px solid #252525;
    border-right: 2px solid #252525;
    content: "";
    display: block;
    height: 5px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 50%;
    -webkit-transform-origin: 66% 66%;
    -ms-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 0.15s ease-in-out;
    -o-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    width: 5px;
}
.nice-select.open:after {
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
}
.nice-select.open .list {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: scale(1) translateY(0);
    -ms-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
}
.nice-select.disabled {
    border-color: #ededed;
    color: #464646;
    pointer-events: none;
}
.nice-select.disabled:after {
    border-color: #585858;
}
.nice-select.wide {
    width: 100%;
}
.nice-select.wide .list {
    left: 0 !important;
    right: 0 !important;
}
.nice-select.right {
    float: right;
}
.nice-select.right .list {
    left: auto;
    right: 0;
}
.nice-select.small {
    font-size: 12px;
    height: 36px;
    line-height: 34px;
}
.nice-select.small:after {
    height: 4px;
    width: 4px;
}
.nice-select.small .option {
    line-height: 34px;
    min-height: 34px;
}
.nice-select .list {
    background-color: #fff;
    border-radius: 0;
    -webkit-box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-top: 4px;
    opacity: 0;
    overflow-x: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    -webkit-transform-origin: 50% 0;
    -ms-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transform: scale(0.75) translateY(-21px);
    -ms-transform: scale(0.75) translateY(-21px);
    transform: scale(0.75) translateY(-21px);
    -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    -o-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    max-height: 310px;
    z-index: 9;
}
.nice-select .list:hover .option:not(:hover) {
    background-color: transparent !important;
}
.nice-select .option {
    cursor: pointer;
    font-weight: 400;
    line-height: 40px;
    list-style: none;
    min-height: 40px;
    outline: none;
    padding-left: 20px;
    padding-right: 31px;
    text-align: left;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}
.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
    background-color: #f6f6f6;
}
.nice-select .option.selected {
    font-weight: bold;
}
.nice-select .option.disabled {
    background-color: transparent;
    color: #464646;
    cursor: default;
}

.no-csspointerevents .nice-select .list {
    display: none;
}
.no-csspointerevents .nice-select.open .list {
    display: block;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.no-scroll {
    overflow: hidden;
}

.fancybox-slide {
    padding: 0 !important;
}

*, *::before, *::after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

img.lazy {
    -webkit-transition: 0s;
    -o-transition: 0s;
    transition: 0s;
}

img.lazy[data-src] {
    opacity: 0;
}

body {
    font-family: "Nunito", "Arial", sans-serif;
    font-size: 18px;
    font-weight: 300;
    color: #222222;
    line-height: 1.3;
}

a {
    text-decoration: none;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.button {
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
}

path {
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

input,
select,
textarea {
    -webkit-appearance: none;
}

.visually-hidden {
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    overflow: hidden;
}

.hidden {
    display: none;
}

.invisible {
    visibility: hidden;
}

.container {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.section-gray {
    background: #F5F5F5;
}

@font-face {
    font-family: 'Merriweather';
    src: url("../../../fonts/Merriweather-Regular.ttf");
    src: local("Merriweather Regular"), local("Merriweather-Regular"), url("../../../fonts/Merriweather-Regular.woff2") format("woff2"), url("../../../fonts/Merriweather-Regular.woff") format("woff"), url("../../../fonts/Merriweather-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Merriweather';
    src: url("../../../fonts/Merriweather-Bold.ttf");
    src: local("Merriweather Bold"), local("Merriweather-Bold"), url("../../../fonts/Merriweather-Bold.woff2") format("woff2"), url("../../../fonts/Merriweather-Bold.woff") format("woff"), url("../../../fonts/Merriweather-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Merriweather';
    src: url("../../../fonts/Merriweather-Light.ttf");
    src: local("Merriweather Light"), local("Merriweather-Light"), url("../../../fonts/Merriweather-Light.woff2") format("woff2"), url("../../../fonts/Merriweather-Light.woff") format("woff"), url("../../../fonts/Merriweather-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Merriweather';
    src: url("../../../fonts/Merriweather-Black.ttf");
    src: local("Merriweather Black"), local("Merriweather-Black"), url("../../../fonts/Merriweather-Black.woff2") format("woff2"), url("../../../fonts/Merriweather-Black.woff") format("woff"), url("../../../fonts/Merriweather-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Nunito';
    src: url("../../../fonts/Nunito-SemiBold.ttf");
    src: url("../../../fonts/Nunito-SemiBold.woff2") format("woff2"), url("../../../fonts/Nunito-SemiBold.woff") format("woff"), url("../../../fonts/Nunito-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Nunito';
    src: url("../../../fonts/Nunito-Regular.ttf");
    src: url("../../../fonts/Nunito-Regular.woff2") format("woff2"), url("../../../fonts/Nunito-Regular.woff") format("woff"), url("../../../fonts/Nunito-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Nunito';
    src: url("../../../fonts/Nunito-Light.ttf");
    src: url("../../../fonts/Nunito-Light.woff2") format("woff2"), url("../../../fonts/Nunito-Light.woff") format("woff"), url("../../../fonts/Nunito-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}
.breadcrumb__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.breadcrumb__item {
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 1.6;
}
.breadcrumb__item:not(:last-of-type):after {
    content: "/";
    margin-left: 5px;
    margin-right: 7px;
    color: #868686;
}
.breadcrumb__item a {
    color: #868686;
}
.breadcrumb__item a span {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
}
.breadcrumb__item:last-of-type span {
    font-weight: bold;
    color: #535458;
}
.breadcrumb__item:not(:last-of-type):hover a {
    text-decoration: underline;
}

.card-grid {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0 12px;
    margin-top: 40px;
    margin-bottom: 100px;
}

.card-grid__item {
    width: calc(25% - 16px);
    margin: 0 8px 16px;
    position: relative;
    background: #F5F5F5;
    padding: 50px 50px 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.card-grid__item--wide {
    width: calc(50% - 16px);
}
.card-grid__item--xxl {
    width: 100%;
}
.card-grid__item--m {
    width: calc(66% - 16px);
}
.card-grid__item--s {
    width: calc(34% - 16px);
}

.card-grid__item-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 45px;
}
.card-grid__item-header svg {
    margin-right: 25px;
}

.card-grid__item-header-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.card-grid__item-title {
    font-family: "Merriweather", "Times New Roman", serif;
    font-size: 32px;
    color: #222222;
    font-weight: 400;
}
.card-grid__item-title:hover, .card-grid__item-title:focus {
    color: #535458;
}

.card-grid__item-desc {
    text-transform: uppercase;
    font-size: 14px;
    color: #707070;
}

.card-grid__item-content {
    font-size: 18px;
    line-height: 1.5;
    max-width: 550px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-bottom: 15px;
}

.card-grid__item-link {
    font-size: 14px;
    text-transform: uppercase;
    color: #707070;
    border-bottom: 1px solid;
    -ms-flex-item-align: start;
    align-self: flex-start;
}
.card-grid__item-link:hover, .card-grid__item-link:focus {
    color: #222222;
}
.catalog {
    display: block;
}

.catalog_first-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 1360px;
    min-height: 161px;
    margin: 0 auto;
    padding: 60px 0;
}

.catalog__breadcrumb-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.catalog__breadcrumb {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 35px;
}

.catalog__qoute {
    font-size: 16px;
    line-height: 1.8;
    color: #868686;
    max-width: 1060px;
}

.catalog__second-section {
    background-color: #F8F8F8;
}
.catalog__second-section .catalog__title {
    text-align: left;
    font-size: 32px;
    margin-bottom: 23px;
    line-height: 1.2;
    font-family: "Merriweather", "Times New Roman", serif;
}

.catalog__container {
    max-width: 1360px;
    width: 100%;
    min-height: 161px;
    margin: 0 auto;
    padding: 60px 0;
}

.catalog__text {
    font-size: 16px;
    line-height: 1.8;
    color: #868686;
}
.catalog__second-section .catalog__text {
    max-width: 665px;
}

.catalog__section {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.catalog__filter-wrap {
    position: relative;
}

.catalog__products {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-auto-flow: row dense;
}

.catalog__product {
    border: 1px solid #DDDDDD;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    padding: 20px;
    grid-column: span 3;
    min-width: 0;
}
.catalog__product--x2 {
    grid-column: span 6;
    background-color: rgba(223, 215, 202, 0.5019607843);
}
.catalog__product:hover {
    -webkit-box-shadow: inset 0 0 0 8px #DDDDDD;
    box-shadow: inset 0 0 0 8px #DDDDDD;
}
.catalog__product:hover .catalog__product-img {
    -webkit-transform: scale(1.03);
    -ms-transform: scale(1.03);
    transform: scale(1.03);
}
.catalog__product:not(.catalog__product--x2):nth-of-type(2n) {
    background-color: rgba(239, 241, 230, 0.5019607843);
}
.catalog__product:not(.catalog__product--x2):nth-of-type(3n) {
    background-color: rgba(223, 214, 217, 0.5019607843);
}
.catalog__product:not(.catalog__product--x2):nth-of-type(4n) {
    background-color: rgba(199, 215, 228, 0.5019607843);
}

.catalog__product-category {
    font-size: 16px;
    line-height: 1.4;
    color: #535458;
}

.catalog__product-title {
    font-size: 24px;
    font-family: "Merriweather", "Times New Roman", serif;
    color: #222222;
    line-height: 1.3;
}
.catalog__product-title:hover {
    color: #707070;
}

.catalog__product-price {
    font-size: 16px;
    line-height: 1.4;
    color: #222222;
}

.catalog__product-slider {
    margin-left: -12px;
    margin-right: -12px;
    margin-bottom: unset !important;
}
.catalog__product-slider .slick-dots {
    bottom: 100%;
    right: 12px;
    width: unset;
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.catalog__product-slider .slick-dots li {
    margin: 0 3px;
    width: unset;
    height: unset;
    background: 0;
    padding: 2px 0;
}
.catalog__product-slider .slick-dots li:after {
    display: none;
}
.catalog__product-slider .slick-dots li button {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #535458;
    position: relative;
    opacity: 0.25;
    font-size: 0;
    cursor: pointer;
    outline: none;
    display: block;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.catalog__product-slider .slick-dots li button:before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    background-color: #535458;
    opacity: 1;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.catalog__product-slider .slick-dots li.slick-active {
    cursor: auto;
}
.catalog__product-slider .slick-dots li.slick-active button {
    opacity: 0.75;
    cursor: auto;
}
.catalog__product-slider .slick-dots li:not(.slick-active):hover button {
    opacity: 1;
}

.catalog__product-img-wrap {
    outline: none;
}

.catalog__product-img {
    width: auto;
    max-height: 290px;
    -o-object-fit: cover;
    object-fit: cover;
    margin: 0 auto;
    -webkit-transition: 0.4s !important;
    -o-transition: 0.4s !important;
    transition: 0.4s !important;
}

.catalog__section-popular {
    margin-top: 235px;
}

.catalog__title {
    text-align: center;
    font-family: "Merriweather", "Times New Roman", serif;
    font-size: 42px;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 35px;
}

.catalog__subtitle {
    text-align: center;
    font-family: "Nunito", "Arial", sans-serif;
    font-size: 21px;
    line-height: 1.3;
    max-width: 810px;
    margin: 0 auto 50px;
    color: #868686;
}

.catalog__popular-slider .slick-arrow {
    background-color: #ffffff;
}

.catalog__popular-item {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    height: 490px;
    background-color: #F5F5F5;
    padding: 35px;
    -webkit-transition: unset;
    -o-transition: unset;
    transition: unset;
    outline: none;
    margin: 0 9px;
}

.catalog__popular-item-wrap {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
}

.catalog__popular-item-title {
    font-family: "Nunito", "Arial", sans-serif;
    font-size: 38px;
    line-height: 1.2;
    margin-bottom: 18px;
    font-weight: 600;
    color: #222222;
    z-index: 1;
}

.catalog__popular-item-price {
    font-family: "Nunito", "Arial", sans-serif;
    font-size: 19px;
    color: #868686;
    line-height: 1.4;
    min-height: 1.4em;
    z-index: 1;
}

.catalog__popular-item-img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    -webkit-transition: 0.4s !important;
    -o-transition: 0.4s !important;
    transition: 0.4s !important;
}
.catalog__popular-item-img:hover {
    -webkit-transform: scale(1.03);
    -ms-transform: scale(1.03);
    transform: scale(1.03);
}

.catalog__section-article {
    margin-top: 235px;
}
.catalog__section-article .catalog__text {
    max-width: 840px;
    margin: 0 auto;
}

.catalog__article-slider-wrap {
    margin-bottom: 40px;
    position: relative;
    padding-bottom: 95px;
}
.catalog__article-slider-wrap:after {
    content: "";
    background-color: #F8F8F8;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: calc(100% - 505px);
    z-index: -1;
}

.catalog__article-slider {
    max-width: 840px;
    margin: 0 auto;
}
.catalog__article-slider .slick-arrow {
    top: calc(100% - 410px);
}
.catalog__article-slider .slick-prev {
    right: calc(100% + 100px);
    left: unset;
}
.catalog__article-slider .slick-next {
    left: calc(100% + 100px);
    -webkit-transform: rotate(180deg) translateY(50%);
    -ms-transform: rotate(180deg) translateY(50%);
    transform: rotate(180deg) translateY(50%);
}
.catalog__article-slider .slick-dots {
    margin-top: 18px;
}

.catalog__article-img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.catalog__ul, .catalog__ol {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
}

.catalog__ol {
    counter-reset: list;
}
.catalog__ol li {
    padding-left: 50px;
    position: relative;
}
.catalog__ol li:before {
    content: counter(list);
    counter-increment: list;
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    left: 0;
    top: -3px;
    color: #DDDDDD;
    font-size: 32px;
    font-family: "Merriweather", "Times New Roman", serif;
    font-weight: 400;
}

.catalog__ul {
    margin-bottom: 50px;
}
.catalog__ul li {
    padding-left: 50px;
    position: relative;
}
.catalog__ul li:before {
    content: "";
    width: 35px;
    height: 1px;
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    left: 0;
    top: 12px;
    background: #707070;
}

.catalog__li {
    width: 45%;
    margin-bottom: 25px;
}

.catalog__about-text {
    margin-bottom: 50px;
}

.catalog__tags {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 auto 40px;
}

.catalog__tag {
    text-decoration: underline;
    color: #868686;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 25px;
    font-size: 12px;
    padding: 6px 11px;
    background: #F5F5F5;
    border-radius: 15px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}
.catalog__tag:hover {
    color: #222222;
}

.catalog__container-tags {
    min-height: unset;
}

.catalog__article-text {
    font-size: 16px;
    line-height: 1.8;
    color: #868686;
    max-width: 840px;
    margin: 0 auto 40px;
}

.catalog__article-title {
    margin-bottom: 15px;
}

.catalog-filter {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-auto-flow: row dense;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}
.catalog-filter.fixed {
    position: fixed;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
}
.catalog-filter.hidden {
    -webkit-transform: translateY(-250px);
    -ms-transform: translateY(-250px);
    transform: translateY(-250px);
}
.catalog-filter.mob-active {
    top: 0 !important;
    z-index: 5;
}
.catalog-filter .catalog-filter__select {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 25px 17px;
    margin-bottom: unset;
    border: 1px solid #DDDDDD;
    font-size: 16px;
    font-weight: 600;
}
.catalog-filter .catalog-filter__select span {
    color: #868686;
}
.catalog-filter .catalog-filter__select:before {
    content: attr(aria-label);
    font-size: 18px;
}
.catalog-filter .catalog-filter__select:after {
    display: none;
}
.catalog-filter .catalog-filter__select:hover, .catalog-filter .catalog-filter__select.open {
    -webkit-box-shadow: inset 0 0 0 8px #DDDDDD;
    box-shadow: inset 0 0 0 8px #DDDDDD;
}
.catalog-filter .list {
    right: 0;
    background-color: #222222;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: unset;
}
.catalog-filter .list .option {
    color: #fff;
    text-align: right;
    font-size: 16px;
    font-weight: 600;
}
.catalog-filter .list .option.focus, .catalog-filter .list .option.selected.focus, .catalog-filter .list .option:hover {
    background: #707070;
}
.catalog-filter .list .option.disabled {
    opacity: 0.5;
    background: unset;
}

.catalog-filter__item {
    grid-column: span 3;
}
.catalog-filter__item:nth-of-type(even) .catalog-filter__select {
    background-color: #F5F5F5;
}
.catalog-filter__item--mob {
    display: none;
    grid-column: span 12 !important;
    background: #fff;
    padding: 20px;
    border-top: 1px solid #DDDDDD;
    border-left: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.catalog-filter__item--mob > span:first-of-type {
    font-family: "Merriweather", "Times New Roman", serif;
    font-size: 18px;
    font-weight: 400;
}
.catalog-filter.fixed .catalog-filter__item--mob > span:first-of-type {
    color: white;
}
.catalog-filter__item--mob > span:last-of-type {
    font-size: 18px;
    color: #535458;
}
.catalog-filter.fixed .catalog-filter__item--mob > span:last-of-type {
    color: #DDDDDD;
}

.catalog-filter.mob-active .catalog-filter__item--mob > span:last-of-type {
    display: none;
}
.catalog-filter.fixed .catalog-filter__item--mob {
    border-top: unset;
    border-bottom: 1px solid #DDDDDD;
    background-color: #222222;
    border-color: #222222;
}

.catalog-filter.fixed:not(.catalog-filter--fast) .catalog-filter__item--mob {
    -webkit-animation: slowBackgroundColor 0.15s;
    animation: slowBackgroundColor 0.15s;
}

.catalog-filter.fixed.catalog-filter--fast .catalog-filter__item--mob {
    -webkit-transition: 0s !important;
    -o-transition: 0s !important;
    transition: 0s !important;
}

.catalog-filter.mob-active .catalog-filter__item--mob {
    border-bottom: 1px solid #535458;
}

@-webkit-keyframes slowBackgroundColor {
    0% {
        background-color: #fff;
        border-color: #DDDDDD;
    }
    99% {
        background-color: #fff;
        border-color: #DDDDDD;
    }
    100% {
        background-color: #222222;
        border-color: #222222;
    }
}

@keyframes slowBackgroundColor {
    0% {
        background-color: #fff;
        border-color: #DDDDDD;
    }
    99% {
        background-color: #fff;
        border-color: #DDDDDD;
    }
    100% {
        background-color: #222222;
        border-color: #222222;
    }
}
.catalog-filter__btn-reset {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 25px 17px;
    cursor: pointer;
    border: 1px solid #DDDDDD;
    color: #868686;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    outline: transparent;
    font-size: 16px;
    font-weight: 600;
    font-family: inherit;
    background-color: #F5F5F5;
}
.catalog-filter__btn-reset:before {
    content: attr(aria-label);
}
.catalog-filter__btn-reset:hover {
    -webkit-box-shadow: inset 0 0 0 8px #DDDDDD;
    box-shadow: inset 0 0 0 8px #DDDDDD;
}

.catalog-filter__mob {
    display: none;
    padding: 100px 0 40px;
    overflow-x: auto;
}
.catalog-filter.mob-active .catalog-filter__mob {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: #272727;
}

.catalog-filter__close {
    display: none;
    background: rgba(255, 255, 255, 0.6);
    border: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: pointer;
    height: 44px;
    margin: 0;
    padding: 10px;
    position: relative;
    -webkit-transition: color 0.2s;
    -o-transition: color 0.2s;
    transition: color 0.2s;
    vertical-align: top;
    visibility: inherit;
    width: 44px;
    color: #ccc;
}
.catalog-filter.mob-active .catalog-filter__close {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
}

.catalog-filter__flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.catalog-filter__group {
    margin-bottom: 35px;
    margin-right: 20px;
    margin-left: 20px;
    width: 100%;
}

.catalog-filter__group-title {
    font-size: 18px;
    color: #fff;
    margin-bottom: 20px;
}

/* The container */
.catalog-filter__label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    padding-left: 65px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    min-height: 28px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #868686;
}

/* Hide the browser's default checkbox */
.catalog-filter__label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    display: none;
}

/* Create a custom checkbox */
.catalog-filter__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 28px;
    width: 28px;
    border-radius: 3px;
    background-color: #F5F5F5;
    opacity: 0.1;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .catalog-filter__checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.catalog-filter__label input:checked ~ .catalog-filter__checkmark {
    opacity: 1;
}

/* Create the catalog-filter__checkmark/indicator (hidden when not checked) */
.catalog-filter__checkmark:after {
    content: "";
    background: url(../../../images/check.svg);
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 10px;
    height: 8px;
}

.category-card {
    background: #E7E7E7;
    border: 1px solid #E7E7E7;
    padding: 40px 30px;
    min-height: 320px;
    max-height: 320px;
}
.category-card:hover {
    background: #F5F5F5;
}
.category-card:hover .category-card__image {
    left: calc(50% + 15px);
}

.category-card__title {
    display: block;
    font-family: "Merriweather", "Times New Roman", serif;
    color: #222222;
    font-size: 32px;
    margin-bottom: 12px;
    font-weight: 400;
}

.category-card__title-add {
    color: #181818;
    font-family: "Nunito", "Arial", sans-serif;
    font-size: 14px;
}

.category-card__image {
    display: block;
    position: absolute;
    max-width: calc(100% - 80px);
    max-height: 100%;
    top: calc(50% + 35px);
    left: calc(50% + 30px);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
    z-index: 1;
}
.category-info {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    max-width: 1188px;
    margin-bottom: 100px;
}

.category-info__cards {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 80px;
}

.category-info__image-wrap {
    width: calc(50% - 4px);
    margin-right: 8px;
}
.category-info__image-wrap img {
    width: 100%;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
}

.category-info__link-wrap {
    width: calc(50% - 4px);
    background: #E7E7E7;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.category-info__link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 300px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 1px solid #868686;
    font-size: 16px;
    font-family: "Merriweather", "Times New Roman", serif;
    font-weight: 400;
    color: #222222;
    padding: 22px 30px;
}
.category-info__link svg {
    margin-left: 30px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}
.category-info__link:hover svg {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
}

.category-info__desc {
    font-size: 18px;
    line-height: 1.5;
    color: #707070;
}
.footer {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #F5F5F5;
    padding: 96px 40px 50px;
}

.footer__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.footer__nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: "Nunito", Arial, sans-serif;
    margin-bottom: 10px;
}

.footer__nav-category {
    padding-right: 25px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    max-width: 255px;
}

.footer__nav-title-wrap {
    min-height: 32px;
}

.footer__nav-title {
    text-decoration: unset;
    font-size: 24px;
    font-family: inherit;
    line-height: 1.3;
    font-weight: 600;
    color: #535458;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.footer__nav-title:hover {
    text-decoration: underline;
}

.footer__nav-text-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 28px;
}

.footer__nav-text {
    font-family: inherit;
    font-size: 18px;
    line-height: 1.2;
    color: #868686;
    margin-bottom: 13px;
}
.footer__nav-text:hover {
    color: #535458;
}

.footer__divider {
    border-bottom: 1px solid #D3D3D3;
}

.footer__divider-text {
    text-align: right;
    margin-bottom: 10px;
}
.footer__divider-text span:first-of-type {
    color: #868686;
}
.footer__divider-text span:last-of-type {
    margin-left: -2px;
    color: #535458;
}

.footer__social-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 29px;
}

.footer__logo {
    margin-left: 15px;
}

.footer__social-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.footer_social-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.footer_social-link:not(:first-of-type) {
    margin-left: 15px;
}

.footer__social-icon:hover path,
.footer__logo:hover path {
    fill: #535458;
    opacity: 0.7;
}

.form input[type=text], .form input[type=email], .form input[type=number], .form input[type=password], .form input[type=tel], .form select, .form textarea {
    padding: 12px 0;
    border: unset;
    border-bottom: 1px solid #E7E7E7;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: #464646;
    width: 100%;
    display: block;
    margin-bottom: 18px;
    -webkit-transition: 0.25s;
    -o-transition: 0.25s;
    transition: 0.25s;
}
.form input[type=text].placeholder, .form input[type=email].placeholder, .form input[type=number].placeholder, .form input[type=password].placeholder, .form input[type=tel].placeholder, .form select.placeholder, .form textarea.placeholder {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    letter-spacing: 0;
}
.form input[type=text]:-moz-placeholder, .form input[type=email]:-moz-placeholder, .form input[type=number]:-moz-placeholder, .form input[type=password]:-moz-placeholder, .form input[type=tel]:-moz-placeholder, .form select:-moz-placeholder, .form textarea:-moz-placeholder {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    letter-spacing: 0;
}
.form input[type=text]::-moz-placeholder, .form input[type=email]::-moz-placeholder, .form input[type=number]::-moz-placeholder, .form input[type=password]::-moz-placeholder, .form input[type=tel]::-moz-placeholder, .form select::-moz-placeholder, .form textarea::-moz-placeholder {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    letter-spacing: 0;
}
.form input[type=text]:-ms-input-placeholder, .form input[type=email]:-ms-input-placeholder, .form input[type=number]:-ms-input-placeholder, .form input[type=password]:-ms-input-placeholder, .form input[type=tel]:-ms-input-placeholder, .form select:-ms-input-placeholder, .form textarea:-ms-input-placeholder {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    letter-spacing: 0;
}
.form input[type=text]::-webkit-input-placeholder, .form input[type=email]::-webkit-input-placeholder, .form input[type=number]::-webkit-input-placeholder, .form input[type=password]::-webkit-input-placeholder, .form input[type=tel]::-webkit-input-placeholder, .form select::-webkit-input-placeholder, .form textarea::-webkit-input-placeholder {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    letter-spacing: 0;
}
.form input[type=text]:focus, .form input[type=email]:focus, .form input[type=number]:focus, .form input[type=password]:focus, .form input[type=tel]:focus, .form select:focus, .form textarea:focus {
    border-bottom: 1px solid #D3D3D3;
    outline: none;
}
.form label {
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 14px;
    line-height: 1.4;
    color: #868686;
    font-weight: 400;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-transition: 0.25s;
    -o-transition: 0.25s;
    transition: 0.25s;
}
.form textarea {
    resize: vertical;
    min-height: 100px;
}
.form input[type=checkbox], .form input[type=radio] {
    display: none;
}
.form input[type=checkbox] + label {
    position: relative;
    font-size: 14px;
    padding-left: 35px;
    margin-bottom: 10px;
    margin-top: 12px;
    cursor: pointer;
    color: black;
    text-align: left;
}
.form input[type=checkbox] + label a {
    color: black;
    text-decoration: underline;
}
.form input[type=checkbox] + label::before {
    content: "";
    position: absolute;
    display: inline-block;
    left: 0;
    top: 5px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    vertical-align: middle;
    width: 25px;
    height: 25px;
    border: 1px solid #222222;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.form input[type=checkbox] + label::after {
    content: "";
    position: absolute;
    display: inline-block;
    left: 0;
    top: 5px;
    vertical-align: middle;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    width: 25px;
    height: 25px;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    background: url(../../../images/check.svg) center/20px 20px no-repeat;
}
.form input[type=checkbox]:checked + label::after {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}
.form input[type=radio] + label {
    position: relative;
    font-size: 14px;
    padding-left: 32px;
    margin-bottom: 10px;
    margin-top: 12px;
    cursor: pointer;
}
.form input[type=radio] + label::before {
    content: "";
    position: absolute;
    display: inline-block;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    vertical-align: middle;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid #ffffff;
}
.form input[type=radio] + label::after {
    content: "";
    position: absolute;
    display: inline-block;
    left: 6px;
    top: 50%;
    vertical-align: middle;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #ffffff;
    -webkit-transform: translateY(-50%) scale(0);
    -ms-transform: translateY(-50%) scale(0);
    transform: translateY(-50%) scale(0);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.form input[type=radio]:checked + label::after {
    content: "";
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.form__block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
}
.form__block .button {
    margin-bottom: 18px;
}

.form__fileinput, .form__group .form__fileinput {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.form__fileinput:focus + label,
.form__fileinput.js_has-focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.form__fileinput + label * {
    /* pointer-events: none; */
    /* in case of FastClick lib use */
}

.form__fileinput + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    /* 4px */
    margin-right: 0.4em;
    /* 4px */
}

.form__fileinput-close {
    vertical-align: middle;
    margin-left: 20px;
    cursor: pointer;
    display: none;
}
.form__fileinput-close svg {
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.form__fileinput-close:hover svg {
    fill: #ffffff;
}

.form__fileinput-close--active {
    display: inline-block;
}

.form__block--2fields {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.form__block--2fields input[type=text], .form__block--2fields input[type=email], .form__block--2fields input[type=number], .form__block--2fields input[type=password], .form__block--2fields input[type=tel], .form__block--2fields .nice-select {
    display: block;
    width: calc(50% - 10px);
}
.form__group {
    position: relative;
}

.header {
    height: 80px;
    max-height: 80px;
    border-bottom: 1px solid #DDDDDD;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #ffffff;
    z-index: 4;
}

.header__logo-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.header__logo-wrap:hover path {
    fill: #535458;
}

.header__menu-btn-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.header__menu-btn {
    background: #ffffff;
    border: 0;
    cursor: pointer;
    padding: 10px;
    outline: none;
}
.header__menu-btn:hover path {
    fill: #535458;
}
.intro-slider .slick-dotted.slick-slider {
    margin-bottom: 0;
}
.intro-slider .slick-dots {
    bottom: 45px;
    right: 140px;
    width: auto;
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}
.intro-slider .slick-dots li {
    width: 2px;
    height: 62px;
    background: rgba(255, 255, 255, 0.7);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    vertical-align: bottom;
    margin: 0 24px 0 0;
    cursor: pointer;
}
.intro-slider .slick-dots li:after {
    display: none;
}
.intro-slider .slick-dots li button {
    display: none;
}
.intro-slider .slick-dots li button:before {
    display: none;
}
.intro-slider .slick-dots .slick-active {
    background: #ffffff;
    height: 102px;
}
.intro-slider .slick-dots .slick-active + li + li {
    height: 45px;
}
.intro-slider .slick-dots .slick-active + li + li + li {
    height: 30px;
}

.intro-slider__body {
    background: #DDDDDD;
    opacity: 0;
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
}
.intro-slider__body.slick-initialized {
    opacity: 1;
}

.intro-slider__item {
    position: relative;
    outline: none;
    cursor: pointer;
    width: 100%;
    height: calc(100vh - 80px);
}

.intro-slider__item-image {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    top: 0;
    left: 0;
}

.intro-slider__item-info {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 300px 45px 250px;
    color: #F5F5F5;
    width: 100%;
}

.intro-slider__item-title {
    font-family: "Merriweather", "Times New Roman", serif;
    font-size: 60px;
    font-weight: 300;
    margin-bottom: 20px;
    display: block;
}

.intro-slider__item-desc {
    max-width: 670px;
    font-size: 16px;
    font-family: "Nunito", "Arial", sans-serif;
    line-height: 1.7;
    color: #E7E7E7;
}
.lang-toggler {
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 18px;
    font-family: "Merriweather", "Times New Roman", serif;
    width: 80px;
    text-align: center;
}

.lang-toggler__variants {
    display: none;
    text-align: center;
}

.lang-toggler__option {
    display: block;
    margin-bottom: 17px;
    color: #535458;
    font-size: 14px;
}

.layout {
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
}
.layout:first-of-type {
    padding-top: 80px;
}

.layout__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.layout__l-side {
    width: 140px;
    min-width: 140px;
    border-right: 1px solid #DDDDDD;
    z-index: 2;
    background-color: #fff;
    position: relative;
}

.layout__content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
    border-right: 1px solid #DDDDDD;
}

.layout__r-side {
    width: 80px;
    min-width: 80px;
    position: relative;
}
.layout__r-side .lang-toggler {
    position: absolute;
    top: calc(100vh - 80px);
    right: 0;
    padding: 30px 20px;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
}

.layout__to-top {
    position: fixed;
    bottom: 20px;
    left: 30px;
}

.layout--slider {
    z-index: 5;
}
.layout--slider .header {
    background: rgba(255, 255, 255, 0.1);
    position: absolute;
    z-index: 5;
}
.layout--slider .header__logo-wrap path, .layout--slider .header__menu-btn path {
    fill: #ffffff;
}
.layout--slider .header__logo-wrap:hover path, .layout--slider .header__menu-btn:hover path {
    fill: #DDDDDD;
}
.layout--slider .header__menu-btn {
    background: none;
}
.layout--slider .intro-slider__item {
    height: calc(100vh + 80px);
}
.layout--slider .layout__slider {
    position: absolute;
    top: -80px;
    left: 0;
    width: 100%;
    height: calc(100% + 80px);
}
.layout--slider .layout__l-side {
    background: rgba(255, 255, 255, 0.1);
    z-index: 4;
}
.layout--slider .layout__content {
    border-right: 0;
}
.layout--slider .layout__r-side {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(255, 255, 255, 0.9)), color-stop(40%, transparent), to(transparent));
    background: -o-linear-gradient(bottom, rgba(255, 255, 255, 0.9), transparent 40%, transparent);
    background: linear-gradient(to top, rgba(255, 255, 255, 0.9), transparent 40%, transparent);
    border-left: 1px solid #DDDDDD;
    z-index: 4;
}
.layout--slider .header__menu-btn-wrap {
    background: rgba(255, 255, 255, 0.1);
}
.layout--slider .lang-toggler {
    position: absolute;
}

.layout--short .layout__r-side {
    position: static;
}
.layout--short .layout__r-side .lang-toggler {
    top: unset;
    bottom: 0;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}
.menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    background: #222222;
    color: #F5F5F5;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    z-index: 5;
    -webkit-transform: translateX(50px);
    -ms-transform: translateX(50px);
    transform: translateX(50px);
}
.menu--active {
    opacity: 1;
    visibility: visible;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.menu__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.menu__l-divider {
    width: 80px;
    min-width: 80px;
    border-right: 1px solid #707070;
    border-bottom: 1px solid #707070;
}

.menu__content {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-right: 1px solid #707070;
    border-bottom: 1px solid #707070;
}

.menu__r-divider {
    min-width: 80px;
    max-width: 80px;
    border-bottom: 1px solid #707070;
}
.menu__r-divider .lang-toggler {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 30px 20px;
}
.menu__r-divider .lang-toggler__option:hover {
    color: #ffffff;
}

.menu__header {
    min-height: 80px;
}
.menu__header .menu__l-divider, .menu__header .menu__r-divider {
    min-height: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.menu__header .menu__content {
    padding: 25px 28px;
}

.menu__logo-wrap {
    opacity: 0.7;
}
.menu__logo-wrap:hover {
    opacity: 1;
}
.menu__logo-wrap:hover path {
    fill: #ffffff;
}

.menu__main {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.menu__groups {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    margin-top: -80px;
    height: calc(100% + 80px);
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.menu__group {
    padding: 85px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-right: 1px solid #707070;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.menu__group:last-child {
    border-right: 0;
}

.menu__list-wrap {
    min-height: 230px;
}

.menu__list-title {
    font-size: 40px;
    font-family: "Merriweather", "Times New Roman", serif;
    line-height: 1.2;
    margin-bottom: 32px;
    display: block;
}

.menu__item {
    margin-bottom: 15px;
}
.menu__item a {
    color: #F5F5F5;
    opacity: 0.5;
}
.menu__item a:hover {
    color: #ffffff;
    opacity: 1;
}

.menu__close {
    background: #383838;
    width: 80px;
    height: 80px;
    border: none;
    cursor: pointer;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    outline: none;
}
.menu__close:hover, .menu__close:focus {
    background: #343333;
}
.news {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-top: 80px;
    padding-bottom: 100px;
}

.news__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 1166px;
    margin-bottom: 85px;
}

.news__title {
    font-size: 60px;
    font-family: "Merriweather", "Times New Roman", serif;
    margin-bottom: 3px;
}

.news__desc {
    font-size: 24px;
    color: #707070;
}
.news__desc strong {
    font-weight: 700;
    font-style: italic;
}

.news__wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.news-card {
    background: #F5F5F5;
    margin: 0 10px;
    padding: 30px;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.news-card__label {
    background: #707070;
    color: #ffffff;
    padding: 6px 23px;
    font-size: 14px;
    margin-bottom: 25px;
}
.news-card__label:hover {
    background: #535458;
}

.news-card__title {
    font-size: 32px;
    font-weight: 400;
    font-family: "Merriweather", "Times New Roman", serif;
    margin-bottom: 3px;
    color: #222222;
    margin-left: 18px;
}
.news-card__title:hover {
    color: #707070;
}

.news-card__pubdate {
    font-size: 14px;
    color: #868686;
    margin-bottom: 30px;
    margin-left: 18px;
}

.news-card__preview {
    font-size: 16px;
    color: #535458;
    line-height: 1.4;
    margin-left: 18px;
}
.news-detail .news-preview {
    min-height: 600px;
}
.news-detail .news-preview__info {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.news-detail .news-preview__content {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
}

.news-detail__preview-card.news-preview {
    margin-bottom: 150px;
}
.news-detail__preview-card .news-preview__title {
    font-weight: 400;
    font-size: 60px;
    margin-bottom: 25px;
}
.news-detail__preview-card .news-preview__image {
    max-width: 650px;
    max-height: 510px;
}
.news-detail__preview-card .news-preview__info {
    width: calc(100% - 300px);
    padding: 44px 480px 45px 220px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.news-detail__preview-card .news-preview__content {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
}

.news-detail__cover {
    position: relative;
    margin-bottom: 100px;
}

.news-detail__cover-logo {
    position: absolute;
    right: 30px;
    bottom: 30px;
}

.news-detail__cover-image {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.news-detail__text-block {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    max-width: 1080px;
    margin: 0 auto 80px;
}
.news-detail__text-block h1, .news-detail__text-block h2, .news-detail__text-block h3 {
    font-size: 40px;
    font-family: "Merriweather", "Times New Roman", serif;
    font-weight: 400;
    margin-bottom: 35px;
    max-width: 800px;
}
.news-detail__text-block p, .news-detail__text-block li {
    font-size: 18px;
    color: #222222;
    max-width: 800px;
    margin-bottom: 25px;
}
.news-detail__text-block ol {
    margin-bottom: 35px;
    counter-reset: list;
}
.news-detail__text-block ol li {
    padding-left: 50px;
    position: relative;
}
.news-detail__text-block ol li:before {
    content: counter(list);
    counter-increment: list;
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    left: 0;
    top: -3px;
    color: #DDDDDD;
    font-size: 32px;
    font-family: "Merriweather", "Times New Roman", serif;
    font-weight: 400;
}
.news-detail__text-block ul {
    margin-bottom: 35px;
}
.news-detail__text-block ul li {
    padding-left: 50px;
    position: relative;
}
.news-detail__text-block ul li:before {
    content: "";
    width: 35px;
    height: 1px;
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    left: 0;
    top: 12px;
    background: #707070;
}
.news-detail__text-block--has-img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 1350px;
}

.news-detail__breadcrumbs {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    max-width: 1350px;
    margin-bottom: 20px;
}

.news-detail__side-image {
    margin-right: 100px;
    max-width: 50%;
    -ms-flex-item-align: start;
    align-self: flex-start;
}

.news-detail__side-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.news-detail__side-text p {
    margin-bottom: 30px;
}

.news-detail__full-image {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 50px;
}
.news-detail__full-image img {
    display: block;
    margin: 0 auto;
}
.news-preview {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    width: 100%;
    min-height: 665px;
    margin-bottom: 100px;
}
.news-preview:last-child {
    margin-bottom: 0;
}

.news-preview__info {
    background: #E7E7E7;
    width: calc(100% - 400px);
    padding: 75px 500px 75px 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.news-preview__image-wrap {
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
}
.news-preview__image-wrap:hover {
    -webkit-transform: translate(-20px, -50%);
    -ms-transform: translate(-20px, -50%);
    transform: translate(-20px, -50%);
}

.news-preview__image {
    display: block;
    -o-object-fit: cover;
    object-fit: cover;
    width: 800px;
    height: 510px;
}

.news-preview__date {
    font-size: 18px;
    color: #707070;
    margin-bottom: 65px;
}

.news-preview__title {
    font-family: "Merriweather", "Times New Roman", serif;
    font-weight: 400;
    font-size: 40px;
    color: #222222;
    margin-bottom: 35px;
}
.news-preview__title:hover {
    color: #868686;
}

.news-preview__content {
    font-size: 24px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    color: #707070;
}

.news-preview__link {
    color: #707070;
    font-size: 24px;
    border-bottom: 1px solid;
    margin-bottom: 50px;
    -ms-flex-item-align: start;
    align-self: flex-start;
}
.news-preview__link:hover {
    color: #222222;
}
.news-slider {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 60px 90px 100px;
}
.news-slider .slick-prev {
    left: -70px;
}
.news-slider .slick-next {
    right: -70px;
}
.portfolio-preview {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    max-width: 1350px;
    margin-bottom: 100px;
}

.portfolio-preview__head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 30px;
    position: relative;
}

.portfolio-preview__title {
    font-size: 40px;
    font-weight: 400;
    font-family: "Merriweather", "Times New Roman", serif;
    margin-bottom: 25px;
}

.portfolio-preview__desc {
    font-size: 18px;
    line-height: 1.3;
    max-width: 520px;
}

.portfolio-preview__insta-btn {
    position: absolute;
    bottom: 0;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 260px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 1px solid #868686;
    font-size: 16px;
    font-family: "Merriweather", "Times New Roman", serif;
    font-weight: 400;
    color: #222222;
    padding: 22px 30px;
}
.portfolio-preview__insta-btn svg {
    margin-left: 40px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}
.portfolio-preview__insta-btn:hover svg {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
}

.portfolio-preview__grid {
    display: block;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 14px;
    -moz-column-gap: 14px;
    column-gap: 14px;
}

.portfolio-preview__item {
    margin-bottom: 14px;
    display: block;
}
.product-card {
    background: #F5F5F5;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 30px;
}

.product-card__category {
    font-size: 14px;
    text-transform: uppercase;
    color: #868686;
    margin-bottom: 40px;
}
.product-card__category:hover, .product-card__category:focus {
    color: #222222;
}

.product-card__img-wrap {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 30px;
}
.product-card__img-wrap:hover img {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
}

.product-card__img {
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
}

.product-card__title {
    font-size: 18px;
    font-weight: 400;
    color: #181818;
    margin-bottom: 5px;
}
.projects {
    background: #69645A url(../../../images/line-bg-pattern-gray.png) repeat;
    background-position: center;
    color: #ffffff;
    padding-top: 100px;
    padding-bottom: 150px;
}

.projects__header {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.projects__bottom {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    max-width: 850px;
    margin: 0 auto;
}

.projects__bottom-title {
    font-size: 40px;
    font-family: "Merriweather", "Times New Roman", serif;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 40px;
}

.projects__bottom-content {
    font-size: 18px;
    font-weight: 400;
    color: #DDDDDD;
    line-height: 1.5;
}

.projects__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 70px;
}

.projects__title {
    font-size: 42px;
    font-weight: 900;
    font-family: "Merriweather", "Times New Roman", serif;
    margin-right: 170px;
    margin-left: 30px;
}

.projects__slider {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 120px;
}

.projects__section-title {
    max-width: 715px;
    font-size: 16px;
    color: #DDDDDD;
    line-height: 1.5;
}
.projects-slider__slide {
    padding-right: 20px;
}
.projects-slider__slide:not(.slick-active) {
    opacity: 0.2;
}
.projects-slider__slide:not(.slick-active) .projects-slider__slide-caption {
    visibility: hidden;
    opacity: 0;
}

.projects-slider__slide-image {
    display: block;
    width: 100%;
    height: 395px;
    -o-object-fit: cover;
    object-fit: cover;
    margin-bottom: 25px;
}

.projects-slider__slide-caption {
    color: #DDDDDD;
    font-size: 18px;
    font-weight: 300;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.socials {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.socials__link {
    width: 28px;
    height: 28px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 15px;
}
.socials__link svg {
    width: 100%;
    height: auto;
}
.socials__link:hover path {
    fill: #535458;
    opacity: 0.7;
}

.quotes-slider {
    max-width: 850px;
    margin: 0 auto;
    padding: 50px 0 150px;
}

.quotes-slider__item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.quotes-slider__item.slick-slide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.quotes-slider__item-title {
    font-size: 42px;
    font-weight: 700;
    font-family: "Merriweather", "Times New Roman", serif;
    margin-bottom: 3px;
}

.quotes-slider__item-desc {
    text-transform: uppercase;
    color: #707070;
    font-size: 16px;
    margin-bottom: 40px;
}

.quotes-slider__item-content {
    font-size: 18px;
    color: #868686;
    line-height: 1.5;
}
.quotes-slider__item-content p {
    margin-bottom: 12px;
}
.quotes-slider__item-content p:last-child {
    margin-bottom: 0;
}
.to-top {
    width: 80px;
    height: 80px;
    background: none;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}
.to-top:hover {
    background: #F8F8F8;
}

.video {
    position: relative;
    margin-bottom: 50px;
    /*&:after {
  	content: '';
  	position: absolute;
  	top: 0;
  	left: 0;
  	width: 100%;
  	height: 100%;
  	background: rgba(0, 0, 0, 0.5);
  }*/
}
.video video {
    display: block;
    max-width: 100%;
}

.video__play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 0;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #ffffff;
    border: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    outline: none;
    z-index: 2;
}
.video__play-btn:focus, .video__play-btn:hover {
    opacity: 0.9;
}

.video__play-icon {
    position: relative;
    right: -3px;
}
.video-section {
    max-width: 1148px;
    margin: 0 auto;
    padding: 120px 0;
}
.video-section--has-gray-cover {
    position: relative;
    padding: 70px 0;
    margin-top: 150px;
}
.video-section--has-gray-cover:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    height: 800px;
    width: 100vw;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background: #E7E7E7;
    z-index: -1;
}

.video-section__title {
    font-size: 42px;
    font-family: "Merriweather", "Times New Roman", serif;
    font-weight: 700;
    max-width: 850px;
    margin-bottom: 80px;
    margin-left: 40px;
}

.video-section__info {
    padding: 0 40px;
    max-width: 920px;
}

.video-section__info-title {
    font-size: 42px;
    font-family: "Merriweather", "Times New Roman", serif;
    font-weight: 700;
    margin-bottom: 4px;
}

.video-section__info-desc {
    color: #707070;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 40px;
    display: block;
}

.video-section__info-content {
    font-size: 18px;
    line-height: 1.5;
    color: #707070;
}
.article {
    font-size: 18px;
    font-family: "Nunito", "Arial", sans-serif;
    line-height: 1.3;
}
.article p {
    margin-bottom: 25px;
}

.products-category {
    max-width: 1175px;
    margin: 0 auto;
}

.products-category__container {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.products-category__title {
    margin-top: 40px;
    margin-bottom: 60px;
    font-family: "Merriweather", "Times New Roman", serif;
    font-size: 60px;
}

.product {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 200px;
}

.product__flex {
    max-width: 1540px;
    margin: 75px auto 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid #DDDDDD;
}

.product__box {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    position: relative;
}
.product__box--description {
    max-width: 530px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}
.product__box--gallery {
    margin-left: 90px;
    max-width: 850px;
    min-height: calc(100vh + 108px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.product__title {
    font-family: "Merriweather", "Times New Roman", serif;
    font-size: 40px;
    margin-bottom: 15px;
    line-height: 1.25;
    font-weight: 400;
}

.product__subtitle {
    font-size: 16px;
    text-transform: uppercase;
    line-height: 1.4;
    margin-bottom: 35px;
    color: #535458;
}

.product__description {
    font-size: 18px;
    line-height: 1.6;
    max-width: 530px;
    color: #464646;
    margin-bottom: 35px;
}

.product__price-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
}

.product__button {
    width: 100%;
    max-width: 290px;
}

.product__price {
    font-family: "Merriweather", "Times New Roman", serif;
    font-size: 24px;
    font-weight: 400;
}

.product__gallery {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 255px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-left: 1px solid #DDDDDD;
    padding-left: 20px;
    overflow: auto;
}
.product__gallery::-webkit-scrollbar {
    display: none;
}

.product__img-wrap {
    width: 100%;
    padding: 35px;
}
.product__img-wrap:not(:last-of-type) {
    border-bottom: 1px solid #DDDDDD;
}

.product__img {
    margin: 0 auto;
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
}
.product__img:hover {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
}

.product__next-item {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #DDDDDD;
    margin-bottom: 55px;
    padding-left: 20px;
}

.product__next-item-wrap {
    width: 100%;
    max-width: 520px;
}

.product__next-item-title {
    background-image: -webkit-gradient(linear, left top, right top, from(#222222), to(#222222));
    background-image: -o-linear-gradient(left, #222222, #222222);
    background-image: linear-gradient(to right, #222222, #222222);
    background-size: 1px 1px;
    background-repeat: repeat-x;
    background-position: 0 100%;
    background-size: 1px 2px;
    background-position: 0 90%;
    display: inline-block;
    font-size: 40px;
    line-height: 1.25;
    font-family: "Merriweather", "Times New Roman", serif;
    color: #222222;
    margin-bottom: 15px;
    font-weight: 400;
}
.product__next-item-title:hover {
    background-image: -webkit-gradient(linear, left top, right top, from(#535458), to(#535458));
    background-image: -o-linear-gradient(left, #535458, #535458);
    background-image: linear-gradient(to right, #535458, #535458);
    background-size: 1px 1px;
    background-repeat: repeat-x;
    background-position: 0 100%;
    background-size: 1px 2px;
    background-position: 0 90%;
    color: #535458;
}

.product__next-item-text {
    font-size: 24px;
    line-height: 1.3;
    margin-bottom: 5px;
    color: #535458;
}

.product__next-item-btn {
    background: #f5f5f5 url(../../../images/arrow-left-gray.svg) center/contain no-repeat;
    background-size: 40% 40% !important;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    cursor: pointer;
    outline: 0;
    margin-left: 35px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}
.product__next-item-btn:hover {
    background: #ddd url(../../../images/arrow-left-gray.svg) center no-repeat;
}

.product__article {
    max-width: 1540px;
    margin: 300px auto 120px;
    padding-top: 100px;
    padding-bottom: 150px;
    background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(27%, transparent), color-stop(27%, #F5F5F5), to(#F5F5F5));
    background: -o-linear-gradient(left, transparent, transparent 27%, #F5F5F5 27%, #F5F5F5 100%);
    background: linear-gradient(to right, transparent, transparent 27%, #F5F5F5 27%, #F5F5F5 100%);
}

.product__article-title {
    font-size: 40px;
    line-height: 1.25;
    font-family: "Merriweather", "Times New Roman", serif;
    margin-bottom: 40px;
    max-width: 790px;
    font-weight: 400;
}

.product__article-text {
    color: #464646;
    font-size: 18px;
    line-height: 1.55;
    max-width: 790px;
    padding-right: 100px;
}

.product__slider-title {
    font-family: "Merriweather", "Times New Roman", serif;
    font-size: 32px;
    margin-bottom: 42px;
    line-height: 1.25;
    font-weight: 400;
    text-align: center;
}

.button {
    padding: 20px 45px;
    font-size: 18px;
    line-height: 1;
    background-color: #ffffff;
    color: #222222;
    border: 1px solid #222222;
    cursor: pointer;
}
.button:hover {
    color: #535458;
    border: 1px solid #535458;
}

.contacts {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.contacts__breadcrumb {
    margin-top: 65px;
}

.contacts__container {
    max-width: 1570px;
    margin: 0 auto;
}

.contacts__flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 40px;
    margin-bottom: 120px;
}

.contacts__map-box {
    margin-right: 60px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.contacts__map {
    width: 100%;
    max-width: 1085px;
    min-height: 550px;
}

.contacts__form-box {
    max-width: 435px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

.contacts__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-top: 50px;
}

.contacts__info-box {
    width: 100%;
    max-width: 300px;
}
.contacts__info-box:last-of-type {
    margin-left: 100px;
}

.contacts__info-title {
    font-size: 24px;
    line-height: 1.4;
    margin-bottom: 20px;
    font-family: "Merriweather", "Times New Roman", serif;
    text-transform: uppercase;
}

.contacts__info-link,
.contacts__info-address,
.contacts__info-link-wrap span {
    font-size: 18px;
    line-height: 2;
    color: #868686;
}

.contacts__form-title {
    font-size: 40px;
    line-height: 1.25;
    font-family: "Merriweather", "Times New Roman", serif;
    margin-bottom: 20px;
}

.contacts__form-text {
    font-size: 14px;
    line-height: 1.4;
    color: #464646;
    margin-bottom: 25px;
}

.contacts__form-button {
    display: block;
    margin-top: 50px;
    margin-left: auto;
    width: 100%;
    max-width: 225px;
}

.product-filter {
    margin-top: 60px;
}

.product-filter__item {
    position: relative;
    margin-bottom: 35px;
}

.product-filter__item-name {
    position: relative;
    font-size: 24px;
    font-family: "Merriweather", "Times New Roman", serif;
    font-weight: 400;
    cursor: pointer;
    margin-bottom: 15px;
    margin-right: 60px;
}
.product-filter__item-name:after {
    content: "";
    position: absolute;
    right: -40px;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    background: url(../../../images/arrow-left.svg) center/contain no-repeat;
    -webkit-transform: translateY(-50%) rotate(270deg);
    -ms-transform: translateY(-50%) rotate(270deg);
    transform: translateY(-50%) rotate(270deg);
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
}
.product-filter__item.active .product-filter__item-name:after {
    -webkit-transform: translateY(-50%) rotate(90deg);
    -ms-transform: translateY(-50%) rotate(90deg);
    transform: translateY(-50%) rotate(90deg);
}

.product-filter__drop {
    display: none;
}

.product-filter__drop-name {
    text-transform: uppercase;
    font-size: 16px;
    color: #535458;
    margin-bottom: 13px;
}

.selection-slider {
    margin-right: 60px;
    margin-bottom: 20px;
    margin-left: -10px;
}
.selection-slider .slick-prev.slick-arrow {
    display: none !important;
}
.selection-slider .slick-next.slick-arrow {
    right: -60px;
    top: 50%;
}

.selection-slider__item {
    margin: 10px;
    display: block;
    width: 60px;
    height: 60px;
    border: 2px solid transparent;
    border-radius: 10px;
    overflow: hidden;
}
.selection-slider__item.active {
    border-color: #fff;
    -webkit-box-shadow: 0 0 9px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.5);
}
.selection-slider__item:not(.active):hover {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
}
.selection-slider--texture .selection-slider__item:not(.active):hover {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
}
.selection-slider--texture .selection-slider__item:not(.active):hover .selection-slider__img:hover {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
}

.selection-slider__img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.order-goods {
    width: 850px;
    max-width: 100%;
}

.order-goods__form-wrap {
    max-width: 330px;
}

.order-goods__form .button {
    width: 100%;
    margin-top: 15px;
}

.order-goods__title {
    font-size: 40px;
    font-weight: bold;
    font-family: "Merriweather", "Times New Roman", serif;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.order-goods__text {
    font-size: 14px;
    line-height: 1.3;
    margin-bottom: 20px;
    color: #464646;
}

.order-goods__img-wrap {
    position: absolute;
    top: 0;
    right: 0;
    left: 420px;
    bottom: 0;
}

.order-goods__img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.modal .fancybox-button {
    width: 62px;
    height: 62px;
    background: white;
    top: 10px;
    right: 10px;
}

.modal__success {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    padding: 40px;
}
.modal__success.active {
    display: block;
}

.modal__success-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
}

.modal__success-title {
    font-size: 40px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 36px;
}

.modal__success-text {
    font-size: 14px;
    line-height: 1.3;
    margin-bottom: 60px;
    max-width: 360px;
    text-align: center;
    color: #464646;
}

.modal__success-btn {
    width: 100%;
    max-width: 225px;
}

.product-slider {
    padding-left: 80px;
    padding-right: 80px;
    margin-bottom: 80px;
}
.product-slider .slick-next {
    right: 0;
    top: 50%;
}
.product-slider .slick-prev {
    left: 0;
    top: 50%;
}

.product-slider__item-wrap {
    padding-left: 10px;
    padding-right: 10px;
}

.product-slider__item {
    display: block;
    width: 470px;
    height: 360px;
    max-width: 100%;
    margin: 0 auto;
    background-color: #F5F5F5;
    padding: 30px;
    outline: none;
}

.product-slider__img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    -webkit-transition: 0.4s !important;
    -o-transition: 0.4s !important;
    transition: 0.4s !important;
}
.product-slider__item:hover .product-slider__img {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
}
@media (max-width: 1800px) {
    .news-detail__preview-card .news-preview__title {
        font-size: 50px;
    }
    .news-detail__preview-card .news-preview__info {
        padding: 44px 420px 45px 130px;
    }
}
@media (max-width: 1680px) {
    .news-preview {
        min-height: 500px;
        margin-bottom: 70px;
    }
    .news-preview__image {
        width: 600px;
        height: 410px;
    }
    .news-preview__content {
        font-size: 20px;
    }
    .news-preview__link {
        font-size: 20px;
    }
    .news-preview__info {
        width: calc(100% - 300px);
        padding: 45px 350px 45px 50px;
    }
}
@media (max-width: 1679px) {
    .news-detail__preview-card.news-preview {
        min-height: 500px;
    }
    .news-detail__preview-card .news-preview__title {
        font-size: 40px;
    }
    .news-detail__preview-card .news-preview__image {
        width: 600px;
        height: 410px;
    }
    .news-detail__preview-card .news-preview__info {
        width: calc(100% - 300px);
        padding: 45px 350px 45px 50px;
    }
    .projects {
        background-size: 350px 10px;
    }
    .projects__title {
        margin-right: 80px;
    }
    .projects__section-title {
        max-width: 655px;
    }
    .projects-slider__slide-image {
        height: 300px;
    }
}
@media (max-width: 1500px) {
    .catalog__popular-item {
        height: 400px;
    }
}
@media (max-width: 1440px) {
    .catalog__breadcrumb-wrap {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .product__box--description {
        max-width: 470px;
        min-height: 1000px;
    }
    .product__box--gallery {
        margin-left: 35px;
    }
    .contacts__flex {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .contacts__map-box {
        margin-top: 50px;
        -ms-flex-item-align: stretch;
        align-self: stretch;
        margin-right: -20px;
        margin-left: -20px;
    }
    .contacts__map {
        max-width: 100%;
    }
    .contacts__form-box {
        max-width: 635px;
        margin-bottom: 25px;
    }
    .contacts__info {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0 50px;
    }
    .contacts__form-button {
        max-width: 100%;
    }
}
@media (max-width: 1439px) {
    .card-grid__item-header {
        margin-bottom: 25px;
    }
    .category-card {
        padding: 20px;
        min-height: 250px;
    }
    .category-card__title {
        font-size: 26px;
        margin-bottom: 6px;
    }
    .category-card__image {
        left: 50%;
    }
    .category-info__cards {
        margin-bottom: 40px;
    }
    .menu__group:last-child {
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
    }
    .menu__group {
        padding: 65px;
    }
    .news-detail__preview-card.news-preview {
        min-height: 400px;
    }
    .news-detail__preview-card .news-preview__info {
        width: calc(100% - 225px);
        padding: 35px 275px 35px 40px;
    }
    .news-detail__preview-card .news-preview__image {
        width: 450px;
        height: 310px;
    }
    .news-detail__side-image {
        margin-right: 60px;
        max-width: 40%;
    }
    .news-preview {
        min-height: 400px;
    }
    .news-preview__image {
        width: 450px;
        height: 310px;
    }
    .news-preview__info {
        width: calc(100% - 225px);
        padding: 35px 275px 35px 40px;
    }
    .news-preview__date {
        margin-bottom: 35px;
    }
    .news-preview__title {
        font-size: 30px;
    }
    .news-preview__content {
        font-size: 18px;
        margin-bottom: 15px;
    }
    .news-preview__link {
        font-size: 18px;
        margin-bottom: 10px;
    }
    .product-card {
        padding: 20px;
        min-height: 250px;
    }
    .product-card__img {
        max-height: 170px;
    }
    .product-card__img-wrap {
        margin-bottom: 20px;
    }
    .projects {
        padding-bottom: 100px;
    }
    .projects__section-title {
        max-width: 545px;
    }
    .projects__title {
        margin-right: 60px;
        font-size: 36px;
    }
    .video-section {
        padding: 80px 20px;
    }
    .video-section--has-gray-cover:before {
        height: 700px;
    }
    .video-section__title {
        margin-bottom: 50px;
    }
}
@media (max-width: 1400px) {
    .catalog__popular-item {
        height: 300px;
    }
}
@media (max-width: 1300px) {
    .catalog__product--x2 {
        grid-column: span 8 !important;
    }
    .catalog__product {
        grid-column: span 4;
    }
    .catalog__product-img {
        max-height: 220px;
    }
    .catalog__popular-item {
        height: 250px;
        padding: 20px;
    }
    .catalog__popular-item-title {
        font-size: 24px;
        margin-bottom: 10px;
    }
    .catalog__popular-item-price {
        font-size: 16px;
    }
    .catalog-filter .catalog-filter__select {
        padding: 10px 17px;
    }
    .catalog-filter__item {
        grid-column: span 4;
    }
    .catalog-filter__item:last-of-type {
        grid-column: span 12;
    }
    .catalog-filter__btn-reset {
        padding: 10px 17px;
    }
    .product__box--description {
        max-width: 390px;
    }
    .product__button {
        max-width: 180px;
    }
}
@media (max-width: 1279px) {
    .menu__group {
        padding: 45px;
    }
}
@media (max-width: 1240px) {
    .product__gallery {
        bottom: 185px;
    }
    .product__next-item {
        padding-bottom: 10px;
    }
    .product__next-item-title {
        font-size: 24px;
    }
    .product__next-item-text {
        font-size: 18px;
    }
}
@media (max-width: 1200px) {
    .catalog__popular-item {
        height: 200px;
    }
    .portfolio-preview__insta-btn {
        position: static;
        margin-top: 20px;
        padding: 15px 30px;
        width: 100%;
    }
    .product__box--description {
        max-width: 350px;
    }
    .product-slider .slick-next {
        right: 30px;
        background-color: #fff;
    }
    .product-slider .slick-prev {
        left: 30px;
        background-color: #fff;
    }
    .product-slider {
        padding-left: 10px;
        padding-right: 10px;
    }
}
@media (max-width: 1199px) and (max-width: 991px) {
    .quotes-slider {
        padding: 0 10px;
    }
}
@media (max-width: 1199px) {
    .card-grid {
        margin-bottom: 45px;
    }
    .card-grid__item {
        padding: 20px;
    }
    .card-grid__item-header svg {
        width: 40px;
        height: 40px;
    }
    .card-grid__item-title {
        font-size: 26px;
    }
    .card-grid__item-content {
        font-size: 16px;
    }
    .category-card__title {
        font-size: 20px;
        margin-bottom: 3px;
    }
    .menu__list-title {
        font-size: 30px;
    }
    .menu__group {
        padding: 25px;
    }
    .news__header {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
    .news__social {
        margin-top: 30px;
    }
    .news-card {
        padding: 25px 20px 40px;
    }
    .news-card__title {
        margin-left: 0;
    }
    .news-card__pubdate {
        margin-left: 0;
    }
    .news-card__preview {
        margin-left: 0;
    }
    .news-preview__image {
        width: 300px;
        height: 200px;
    }
    .news-preview__info {
        width: calc(100% - 100px);
        padding: 35px 250px 35px 40px;
    }
    .projects {
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .projects__header {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-bottom: 50px;
    }
    .projects__title {
        margin-left: 0;
        margin-bottom: 26px;
    }
    .projects__section-title {
        max-width: 100%;
    }
    .projects__slider {
        margin-bottom: 70px;
    }
    .quotes-slider {
        width: 100%;
        max-width: 100%;
        padding: 0 20px;
        margin: 0 auto;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        margin-bottom: 100px;
    }
    .video__play-btn {
        width: 65px;
        height: 65px;
    }
    .video__play-icon {
        width: 18px;
        right: -1px;
    }
    .video-section--has-gray-cover:before {
        height: 60%;
    }
    .video-section__title {
        margin-left: 0;
        font-size: 35px;
        margin-bottom: 30px;
    }
    .video-section__info {
        padding: 0;
    }
    .video-section__info-desc {
        margin-bottom: 20px;
    }
    .video-section__info-title {
        font-size: 35px;
    }
}
@media (max-width: 991px) {
    .container {
        padding: 0 10px;
    }
    .card-grid {
        padding: 0 10px;
    }
    .card-grid {
        margin: 40px -5px 50px;
        min-width: calc(100% + 10px);
    }
    .card-grid__item {
        width: calc(50% - 10px);
        margin: 0 5px 10px;
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        min-height: 220px;
    }
    .card-grid__item--wide {
        width: calc(100% - 10px);
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }
    .card-grid__item--wide:nth-child(n+3) {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
    }
    .catalog__container {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .catalog__section {
        padding: 0 10px;
    }
    .catalog__product--x2 {
        grid-column: span 12 !important;
    }
    .catalog__product {
        grid-column: span 6;
    }
    .catalog__product-img {
        max-height: 270px;
    }
    .catalog__popular-item {
        height: 250px;
    }
    .catalog__section-article {
        margin-top: 50px;
        padding-top: 50px;
        background-color: #F8F8F8;
        padding-left: 10px;
        padding-right: 10px;
    }
    .catalog__article-slider-wrap {
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 40px;
    }
    .catalog__article-slider {
        -webkit-transform: unset;
        -ms-transform: unset;
        transform: unset;
    }
    .catalog-filter__item--mob {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }
    .catalog-filter__item {
        display: none;
    }
    .category-card {
        width: 100% !important;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: 60px;
    }
    .category-card__image {
        z-index: 2;
        top: calc(50% + 15px);
    }
    .category-info {
        padding: 0 10px;
    }
    .footer {
        padding: 0 10px;
    }
    .footer {
        padding-top: unset;
        padding-bottom: unset;
    }
    .footer__nav {
        display: none;
    }
    .footer__divider {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        background: #fff;
        border: unset;
        margin-left: -10px;
        margin-right: -10px;
        padding-right: 7px;
    }
    .footer__divider-text {
        margin-bottom: 5px;
        margin-top: 5px;
    }
    .footer__social-flex {
        padding-bottom: 29px;
    }
    .footer__logo {
        margin-left: unset;
    }
    .header {
        height: 66px;
        max-height: 66px;
    }
    .header .layout__content {
        -webkit-box-flex: unset;
        -ms-flex-positive: unset;
        flex-grow: unset;
    }
    .header .layout__l-side,
    .header .layout__r-side {
        max-width: unset;
    }
    .header__logo-wrap.layout__l-side {
        width: 150px;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        padding: 20px;
    }
    .header__menu-btn-wrap.layout__r-side {
        width: 66px;
    }
    .intro-slider .slick-dots {
        bottom: 60px;
        right: 10px;
        width: auto;
    }
    .intro-slider .slick-dots li {
        width: 2px;
        height: 25px;
        background: rgba(255, 255, 255, 0.7);
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        vertical-align: bottom;
        margin: 0 12px 0 0;
    }
    .intro-slider .slick-dots .slick-active {
        background: #ffffff;
        height: 40px;
    }
    .intro-slider .slick-dots .slick-active + li + li {
        height: 20px;
    }
    .intro-slider .slick-dots .slick-active + li + li + li {
        height: 12px;
    }
    .intro-slider__item {
        height: calc(100vh - 66px);
    }
    .intro-slider__item-info {
        padding: 0 80px 60px 20px;
    }
    .intro-slider__item-title {
        font-size: 32px;
        font-weight: 400;
        margin-bottom: 15px;
    }
    .intro-slider__item-desc {
        font-size: 14px;
        line-height: 1.4;
    }
    .layout:first-of-type {
        padding-top: 66px;
    }
    .layout .lang-toggler {
        display: none;
    }
    .layout__l-side {
        width: 0;
        border: none;
        min-width: unset;
    }
    .layout__r-side {
        width: 0;
        min-width: unset;
    }
    .layout__content {
        border: none;
    }
    .layout__to-top {
        display: none;
    }
    .layout--slider .header {
        border-bottom: none;
    }
    .layout--slider .header__logo-wrap {
        background: rgba(34, 34, 34, 0.9);
        border: none;
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }
    .layout--slider .header__menu-btn-wrap {
        background: rgba(34, 34, 34, 0.9);
        border: none;
    }
    .layout--slider .layout__l-side {
        display: none;
    }
    .layout--slider .layout__r-side {
        border: 0;
    }
    .menu {
        background: #444444;
    }
    .menu__l-divider {
        display: none;
    }
    .menu__r-divider {
        min-width: 66px;
        max-width: 66px;
        border-bottom: none;
    }
    .menu__r-divider .lang-toggler {
        width: 66px;
        display: none;
    }
    .menu__r-divider .lang-toggler__option {
        color: #868686;
    }
    .menu__header {
        min-height: 66px;
        height: 66px;
    }
    .menu__header .menu__l-divider {
        display: none;
    }
    .menu__header .menu__r-divider {
        min-height: 66px;
        min-width: 66px;
        max-width: 66px;
    }
    .menu__header .menu__content {
        padding: 20px;
    }
    .menu__groups {
        margin-top: 0;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .menu__group {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        border-right: 0;
        padding: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        width: 100%;
    }
    .menu__group:last-child {
        min-width: calc(100% + 107px);
        margin-left: -20px;
        padding: 20px 20px 30px;
        border-top: 1px solid #707070;
    }
    .menu__group:last-child .menu__list-wrap {
        margin-bottom: 0;
    }
    .menu__group:last-child .menu__list-title {
        display: none;
    }
    .menu__main {
        overflow: auto;
    }
    .menu__list {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
    }
    .menu__close {
        width: 66px;
        height: 65px;
        background: #4E4E4E;
        border-bottom: 1px solid #707070;
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        position: absolute;
        max-width: 66px;
        padding: 0;
        top: 0;
        right: 0;
    }
    .menu__list-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        min-height: unset;
        width: 100%;
        margin-bottom: 38px;
    }
    .menu__list-wrap:last-child {
        margin-bottom: 0;
    }
    .menu__content {
        padding: 20px;
    }
    .menu__list-title {
        font-size: 18px;
        min-width: 130px;
    }
    .menu__item {
        margin-bottom: 10px;
    }
    .menu__item:last-child {
        margin-bottom: 0;
    }
    .menu__item a {
        font-size: 16px;
    }
    .news {
        padding: 0 10px;
    }
    .news {
        padding: 50px 20px;
    }
    .news__title {
        font-size: 24px;
        font-weight: 900;
        margin-bottom: 10px;
    }
    .news__desc {
        font-size: 16px;
    }
    .news__social {
        margin-top: 20px;
    }
    .news__header {
        margin-bottom: 40px;
    }
    .news-detail__text-block {
        padding: 0 10px;
    }
    .news-detail__breadcrumbs {
        padding: 0 10px;
    }
    .news-detail__full-image {
        padding: 0 10px;
    }
    .news-detail .news-preview {
        min-height: unset;
    }
    .news-detail__preview-card.news-preview {
        margin-bottom: 50px;
        min-height: unset;
    }
    .news-detail__preview-card .news-preview__info {
        padding: 200px 18px 40px;
        width: 100%;
    }
    .news-detail__preview-card .news-preview__image {
        width: 100%;
        max-width: 100%;
        height: 100%;
    }
    .news-detail__preview-card .news-preview__image-wrap {
        position: absolute;
        max-height: 205px;
        height: 205px;
        width: calc(100% - 46px);
        max-width: calc(100% - 46px);
        left: 50%;
        top: -50px;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    }
    .news-detail__preview-card .news-preview__image-wrap:hover {
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    }
    .news-detail__preview-card .news-preview__title {
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 15px;
    }
    .news-detail__cover {
        margin-bottom: 40px;
    }
    .news-detail__cover-image {
        min-height: 300px;
    }
    .news-detail__cover-logo {
        display: none;
    }
    .news-detail__preview-card {
        margin-top: 100px;
    }
    .news-detail__text-block {
        margin-bottom: 30px;
    }
    .news-detail__text-block h1, .news-detail__text-block h2, .news-detail__text-block h3 {
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 25px;
    }
    .news-detail__text-block p, .news-detail__text-block li {
        font-size: 16px;
        margin-bottom: 20px;
    }
    .news-detail__text-block ol, .news-detail__text-block ul {
        margin-top: 40px;
    }
    .news-detail__text-block--has-img {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .news-detail__side-image {
        margin: 0 -10px 25px;
        max-width: calc(100% + 20px);
        width: calc(100% + 20px);
        max-height: 460px;
        -o-object-fit: cover;
        object-fit: cover;
    }
    .news-detail__full-image {
        margin: 0 -10px 50px;
        max-width: calc(100% + 20px);
        width: calc(100% + 20px);
    }
    .news-preview {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        min-height: unset;
    }
    .news-preview__image-wrap {
        position: static;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        overflow: hidden;
    }
    .news-preview__image-wrap:hover {
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
    }
    .news-preview__image-wrap:hover img {
        -webkit-transform: scale(1.2);
        -ms-transform: scale(1.2);
        transform: scale(1.2);
    }
    .news-preview__image {
        width: 100%;
        height: auto;
        -webkit-transition: 1.3s;
        -o-transition: 1.3s;
        transition: 1.3s;
    }
    .news-preview__info {
        width: 100%;
        padding: 25px;
    }
    .news-preview__date {
        margin-bottom: 18px;
    }
    .news-preview__title {
        font-size: 24px;
        margin-bottom: 15px;
    }
    .news-preview__link {
        display: none;
    }
    .news-preview__content {
        margin-bottom: 5px;
    }
    .news-slider {
        padding: 0 10px;
    }
    .news-slider {
        padding: 50px 90px;
    }
    .portfolio-preview {
        padding: 0 10px;
    }
    .projects__header {
        padding: 0 10px;
    }
    .projects__bottom {
        padding: 0 10px;
    }
    .projects__slider {
        padding: 0 10px;
    }
    .projects__bottom-title {
        font-size: 30px;
        line-height: 1.3;
        margin-bottom: 30px;
        max-width: 500px;
    }
    .quotes-slider {
        margin-top: 50px;
    }
    .products-category__container {
        padding: 0 10px;
    }
    .products-category__title {
        font-size: 40px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .product {
        padding: 0 10px;
    }
    .product {
        margin-bottom: 50px;
    }
    .product__flex {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        border-bottom: unset;
        margin-top: 50px;
    }
    .product__box--description {
        min-height: unset;
        max-width: unset;
        -ms-flex-item-align: stretch;
        align-self: stretch;
    }
    .product__box--gallery {
        margin-left: -20px;
        margin-right: -20px;
        -ms-flex-item-align: stretch;
        align-self: stretch;
        max-width: unset;
        border-top: 1px solid #DDDDDD;
    }
    .product__title {
        font-size: 24px;
        font-weight: 900;
        margin-bottom: 10px;
    }
    .product__subtitle {
        font-size: 14px;
        margin-bottom: 25px;
    }
    .product__gallery {
        position: unset;
        border-left: unset;
        padding-left: unset;
    }
    .product__img-wrap:not(:last-of-type) {
        border-bottom: unset;
        padding: 20px 0;
    }
    .product__img-wrap:last-of-type {
        border-bottom: 1px solid #DDDDDD;
    }
    .product__next-item {
        padding-top: 20px;
        padding-right: 20px;
    }
    .product__next-item-wrap {
        max-width: unset;
    }
    .product__next-item-text {
        font-size: 14px;
    }
    .product__next-item-btn {
        width: 30px;
        height: 30px;
    }
    .product__article {
        margin-top: 0;
        margin-bottom: 50px;
        padding: 80px 10px 80px;
    }
    .product__article-title {
        font-size: 24px;
        font-weight: 900;
        margin-bottom: 25px;
    }
    .product__article-text {
        font-size: 14px;
        padding-right: unset;
    }
    .product__slider-title {
        font-size: 24px;
        font-weight: 900;
        margin-bottom: 25px;
    }
    .product__video-section {
        margin-left: -10px;
        margin-right: -10px;
    }
    .contacts {
        padding: 0 10px;
    }
    .contacts__info-title {
        font-size: 20px;
    }
    .contacts__form-title {
        font-size: 30px;
    }
    .product-filter__item-name {
        font-size: 18px;
    }
    .product-filter__drop-name {
        font-size: 14px;
        text-transform: unset;
    }
    .product-slider {
        margin-bottom: 30px;
    }
    .product-slider__item {
        height: 260px;
    }
}
@media (max-width: 768px) {
    .slick-arrow {
        width: 30px;
        height: 30px;
    }
    .slick-next {
        right: 15px;
    }
    .slick-prev {
        left: 15px;
    }
    .slick-dots {
        margin-top: 20px;
    }
    .fancybox-content {
        padding: 35px;
    }
    .card-grid__item {
        min-height: 0;
    }
    .card-grid__item-icon {
        display: none;
    }
    .card-grid__item-title {
        font-size: 18px;
        margin-bottom: 6px;
    }
    .card-grid__item-desc {
        font-size: 12px;
    }
    .card-grid__item-header {
        margin-bottom: 20px;
    }
    .card-grid__item-content {
        font-size: 14px;
    }
    .card-grid__item-link {
        font-size: 11px;
    }
    .catalog__qoute {
        font-size: 14px;
        line-height: 1.5;
    }
    .catalog__second-section .catalog__title {
        font-size: 24px;
        font-weight: 900;
    }
    .catalog__text {
        font-size: 14px;
        line-height: 1.5;
    }
    .catalog__section-popular {
        margin-top: 50px;
    }
    .catalog__title {
        font-size: 24px;
    }
    .catalog__subtitle {
        font-size: 16px;
        margin-bottom: 35px;
    }
    .catalog__section-article .catalog__title {
        font-size: 22px;
        margin-bottom: 14px;
    }
    .catalog__ul, .catalog__ol {
        font-size: 14px;
        line-height: 1.4;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 25px;
    }
    .catalog__li {
        width: auto;
        margin-bottom: 15px;
    }
    .catalog__about-text {
        padding-left: 10px;
        padding-right: 10px;
    }
    .catalog__tags {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        overflow-x: scroll;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        margin-bottom: unset;
    }
    .catalog__section--about .catalog__title {
        font-size: 32px;
        font-weight: 400;
        text-align: left;
    }
    .catalog__container-tags {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .catalog__article-text {
        font-size: 14px;
        line-height: 1.5;
    }
    .catalog-filter__item--mob > span:first-of-type {
        font-size: 14px;
    }
    .catalog-filter__item--mob > span:last-of-type {
        font-size: 14px;
    }
    .catalog-filter__close {
        width: 39px;
        height: 39px;
        background: unset;
    }
    .catalog-filter__close svg {
        fill: #F5F5F5;
    }
    .category-card {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    }
    .category-card .category-card__image {
        left: calc(65% + 10px);
    }
    .category-card {
        padding: 10px;
        min-height: 140px;
        max-height: 140px;
    }
    .category-card__title {
        font-size: 14px;
        z-index: 3;
        position: relative;
    }
    .card-grid__item--wide .category-card__title {
        font-size: 18px;
    }
    .category-card__title-add {
        display: none;
    }
    .category-card__image {
        top: calc(50% + 10px);
        left: calc(50% + 10px);
        height: 100%;
        -o-object-fit: contain;
        object-fit: contain;
        max-width: calc(100% - 40px);
    }
    .category-info {
        padding: 0 20px;
    }
    .category-info__cards {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: 0 -20px 20px;
        width: calc(100% + 40px);
    }
    .category-info__image-wrap {
        width: 100%;
        margin: 0;
        max-height: 250px;
        min-height: 250px;
        margin-bottom: 10px;
    }
    .category-info__image-wrap img {
        height: 250px;
    }
    .category-info__link-wrap {
        width: 100%;
        min-height: 200px;
    }
    .category-info__link {
        padding: 15px 30px;
    }
    .category-info__desc {
        font-size: 15px;
        color: #222;
    }
    .form__block--2fields {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .form__block--2fields input[type=text], .form__block--2fields input[type=email], .form__block--2fields input[type=number], .form__block--2fields input[type=password], .form__block--2fields input[type=tel], .form__block--2fields .nice-select {
        display: block;
        width: 100%;
    }
    .news-card__label {
        margin-bottom: 15px;
    }
    .news-card__title {
        font-size: 26px;
    }
    .news-card__pubdate {
        margin-bottom: 15px;
    }
    .news-preview {
        margin-bottom: 40px;
    }
    .news-preview__date {
        font-size: 14px;
        margin-bottom: 15px;
    }
    .news-preview__info {
        padding: 20px;
    }
    .news-preview__content {
        font-size: 16px;
    }
    .news-slider {
        padding: 40px 0;
    }
    .news-slider .slick-arrow {
        width: 40px;
        height: 40px;
        opacity: 0.7;
        top: 55%;
    }
    .news-slider .slick-arrow:focus {
        opacity: 1;
    }
    .news-slider .slick-prev {
        left: 15px;
        background-color: #ffffff;
    }
    .news-slider .slick-next {
        right: 15px;
        background-color: #ffffff;
    }
    .portfolio-preview__grid {
        -webkit-column-gap: 8px;
        -moz-column-gap: 8px;
        column-gap: 8px;
    }
    .portfolio-preview__item {
        margin-bottom: 8px;
    }
    .portfolio-preview__title {
        font-size: 24px;
        font-weight: 400;
    }
    .product-card {
        padding: 10px;
    }
    .product-card__img {
        max-height: 140px;
    }
    .product-card__img-wrap {
        margin-bottom: 10px;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
    }
    .product-card__category {
        font-size: 12px;
        margin-bottom: 15px;
    }
    .product-card__title {
        font-size: 14px;
        font-family: "Merriweather", "Times New Roman", serif;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
    }
    .product-card__price {
        font-size: 12px;
    }
    .projects {
        padding-top: 50px;
        padding-bottom: 50px;
        background-size: 176px 10px;
    }
    .projects__title {
        font-size: 24px;
        margin-right: 0;
        margin-bottom: 20px;
    }
    .projects__section-title {
        font-size: 14px;
    }
    .projects__header {
        margin-bottom: 40px;
    }
    .projects__slider {
        margin-bottom: 50px;
    }
    .projects__bottom-title {
        font-size: 24px;
        font-weight: 900;
        font-family: "Merriweather", "Times New Roman", serif;
    }
    .projects__bottom-content {
        font-size: 14px;
    }
    .projects-slider__slide {
        padding-right: 10px;
    }
    .projects-slider__slide-image {
        height: 125px;
        margin-bottom: 6px;
    }
    .projects-slider__slide-caption {
        font-size: 12px;
    }
    .quotes-slider {
        margin-bottom: 80px;
    }
    .quotes-slider__item-title {
        font-size: 24px;
        font-weight: 900;
        margin-bottom: 5px;
    }
    .quotes-slider__item-desc {
        font-size: 14px;
        max-width: 260px;
        line-height: 1.4;
        color: #222222;
        margin-bottom: 20px;
    }
    .quotes-slider__item-content {
        font-size: 14px;
        color: #222222;
    }
    .video-section {
        padding: 50px 20px;
    }
    .video-section .video {
        width: calc(100% + 40px);
        margin: 0 -20px 30px;
    }
    .video-section--has-gray-cover {
        margin-top: 50px;
    }
    .video-section--has-gray-cover:before {
        height: 250px;
    }
    .video-section__title {
        font-size: 24px;
        font-weight: 900;
    }
    .video-section__info-title {
        font-size: 24px;
        font-weight: 900;
    }
    .video-section__info-desc {
        font-size: 14px;
    }
    .video-section__info-content {
        font-size: 15px;
        color: #222222;
    }
    .article {
        font-size: 14px;
    }
    .products-category__title {
        font-size: 24px;
    }
    .contacts__breadcrumb {
        padding-left: 10px;
        padding-right: 10px;
    }
    .contacts__flex {
        margin-bottom: 60px;
    }
    .contacts__form-box {
        margin-bottom: 15px;
    }
    .contacts__info {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .contacts__info-box:last-of-type {
        margin-left: unset;
        padding-top: 40px;
        margin-top: 40px;
        border-top: 1px solid #DDDDDD;
    }
    .contacts__info-box {
        width: 100%;
        max-width: unset;
        padding-left: 30px;
        padding-right: 30px;
    }
    .contacts__info-title {
        font-size: 18px;
        text-transform: unset;
    }
    .contacts__info-link,
    .contacts__info-address,
    .contacts__info-link-wrap span {
        font-size: 16px;
        line-height: 1.4;
    }
    .contacts__form-title {
        font-size: 24px;
        margin-bottom: 10px;
    }
    .product-filter__item-name:after {
        width: 15px;
        height: 15px;
    }
    .product-filter__item-name {
        margin-right: 48px;
    }
    .selection-slider {
        margin-right: 30px;
    }
    .selection-slider .slick-next.slick-arrow {
        right: -30px;
    }
    .order-goods__form-wrap {
        max-width: 100%;
        margin-top: 270px;
    }
    .order-goods__img-wrap {
        left: 0;
        bottom: calc(100% - 245px);
    }
    .modal {
        min-height: 100vh;
    }
    .product-slider__item {
        height: 120px;
        width: 150px;
    }
}
@media (max-width: 767px) {
    .catalog__container {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .catalog__product-category {
        font-size: 12px;
    }
    .catalog__product-title {
        font-size: 18px;
    }
    .catalog__product-price {
        font-size: 12px;
    }
    .catalog__product-slider .slick-dots li {
        margin: 0 2px;
    }
    .catalog__product-slider .slick-dots li button:before {
        width: 7px;
        height: 7px;
    }
    .catalog__product-slider .slick-dots li button:before {
        width: 4px;
        height: 4px;
    }
    .catalog__product-slider .slick-dots li button {
        width: 12px;
        height: 14px;
    }
    .catalog__product-slider .slick-dots li button {
        width: 4px;
        height: 4px;
        padding: 4px;
    }
    .catalog__product-img {
        max-height: 200px;
    }
    .catalog__popular-item-wrap:not(.slick-center) {
        opacity: 0.6;
    }
    .contacts__map {
        min-height: 400px;
    }
}
@media (max-width: 600px) {
    html {
        height: 100%;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
    }
}
@media all and (max-width: 576px) {
    .fancybox-thumbs {
        width: 110px;
    }
    .fancybox-show-thumbs .fancybox-inner {
        right: 110px;
    }
    .fancybox-thumbs__list a {
        max-width: calc(100% - 10px);
    }
}
@media all and (max-height: 576px) {
    .fancybox-slide {
        padding-left: 6px;
        padding-right: 6px;
    }
    .fancybox-slide--image {
        padding: 6px 0;
    }
    .fancybox-close-small {
        right: -6px;
    }
    .fancybox-slide--image .fancybox-close-small {
        background: #4e4e4e;
        color: #f2f4f6;
        height: 36px;
        opacity: 1;
        padding: 6px;
        right: 0;
        top: 0;
        width: 36px;
    }
    .fancybox-caption {
        padding-left: 12px;
        padding-right: 12px;
    }
    @supports (padding: max(0px)) {
        .fancybox-caption {
            padding-left: max(12px, env(safe-area-inset-left));
            padding-right: max(12px, env(safe-area-inset-right));
        }
    }
}
@media (max-width: 550px) {
    .catalog__subtitle {
        font-size: 16px;
        margin-bottom: 25px;
    }
}
@media (max-width: 425px) {
    .catalog__qoute {
        font-size: 12px;
    }
    .catalog__text {
        font-size: 12px;
    }
    .catalog__product-title {
        font-size: 14px;
    }
    .catalog__title {
        font-size: 18px;
    }
    .catalog__popular-item-title {
        font-size: 18px;
        margin-bottom: 5px;
    }
    .catalog__popular-item-price {
        font-size: 14px;
    }
    .catalog__article-text {
        font-size: 12px;
    }
    .product__flex {
        margin-top: 25px;
    }
    .contacts__breadcrumb {
        margin-top: 25px;
    }
    .contacts__flex {
        margin-top: 25px;
    }
    .contacts__info {
        padding: 0 30px;
    }
    .contacts__info-box {
        padding-left: 20px;
        padding-right: 20px;
    }
}
@media (max-width: 424px) {
    .fancybox-content {
        padding: 20px;
    }
    .contacts__map {
        min-height: 200px;
    }
    .order-goods__form-wrap {
        margin-top: 250px;
        margin-bottom: 35px;
    }
    .order-goods__title {
        font-size: 24px;
    }
}
@media (max-width: 375px) {
    .catalog__product {
        padding: 10px;
    }
    .catalog__product-img {
        max-height: 130px;
    }
    .catalog__popular-item {
        height: 220px;
    }
}
@media (max-width: 374px) {
    .product__button {
        padding-left: 15px;
        padding-right: 15px;
        max-width: 140px;
    }
}
@media (max-width: 370px) {
    .projects {
        background-size: 170px 10px;
    }
}
@media (max-width: 359px) {
    .menu__list-title {
        min-width: 110px;
    }
    .menu__list-wrap {
        margin-bottom: 25px;
    }
    .category-info__link {
        padding: 15px 30px;
        width: calc(100% - 30px);
        font-size: 14px;
    }
    .category-info__link-wrap {
        width: 100%;
        min-height: 170px;
    }
    .video-section__title {
        font-size: 20px;
    }
    .video-section__info-title {
        font-size: 20px;
    }
}
@media (min-width: 425px) {
    .catalog-filter__group {
        width: calc(50% - 40px);
    }
}
@media (min-width: 650px) {
    .catalog-filter__group {
        width: calc(33% - 40px);
    }
}
