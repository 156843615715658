@charset "UTF-8";

/* vendor */
@import "~toastr/toastr";

/* theme */
@import "./theme/style";

.meta-btn {
  position: fixed;
  right: 10px;
  top: 90px;
  z-index: 9999;
}

.meta_modal {
  color: #000!important;

  .h1, .h2, .h3, .h4, .h5, h1, h2, h3, h4, h5 {
    color: #000!important;
  }

  @import "~bootstrap/scss/bootstrap";
}

.selection-slider__item {
    position: relative;

    .selection-slider__img {
        position: absolute;
        z-index: 10;
    }

    .value_short_category {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 20;
        color: #fff;
        text-shadow: 1px 1px 1px #000;
    }
}

.form {
    .form__line.invalid {
        label {
            color: #ff0000;
        }
    }
}

.menu__list-title {
    a {
        color: #F5F5F5 !important;
    }
}



